import React from 'react';
import { NotificationManager } from 'react-notifications';
import axios from 'src/api/axios';
import heart from 'src/assets/svg/heart.svg';
import heartPurpleFull from 'src/assets/svg/heart-purple-full.svg';

const AdFavoriteButton = ({ adId, isFavorited, onUpdate }) => {
  const handleAddToFavorite = async () => {
    try {
      const response = await axios.post(`api/article/${adId}/favorite`);
      NotificationManager.success(response.data.message ?? 'Advertisement successfully added to favorites');
      onUpdate(response.data.data);
    } catch (error) {
      NotificationManager.error(error.response?.data?.message ?? 'Failed to add advertisement to favorites');
    }
  }

  const handleRemoveFromFavorite = async () => {
    try {
      const response = await axios.delete(`api/article/${adId}/favorite`);
      NotificationManager.success(response.data.message ?? 'Advertisement successfully removed from favorites');
      onUpdate(response.data.data);
    } catch (error) {
      NotificationManager.error(error.response?.data?.message ?? 'Failed to remove advertisement from favorites');
    }
  }

  return (
    <button 
      className='w-10 h-10 min-w-10 rounded-full flex mt-2 md:mt-0' 
      onClick={isFavorited ? handleRemoveFromFavorite : handleAddToFavorite}
      aria-label={isFavorited ? 'Remove advertisement from favorites' : 'Add advertisement to favorites'}
    >
      <img 
        src={isFavorited ? heartPurpleFull : heart} 
        alt={isFavorited ? 'Remove from favorites' : 'Add to favorites'} 
        className='m-auto' 
      />
    </button>
  );
};

export default AdFavoriteButton; 
import React from 'react';
import ad1 from 'src/assets/images/ad1.png';
import time from 'src/assets/svg/time.svg';
import pin from 'src/assets/svg/location.svg';
import heart from 'src/assets/svg/heart.svg';
import heartPurpleFull from 'src/assets/svg/heart-purple-full.svg';
import camera from 'src/assets/svg/camera.svg';
import { Link } from 'react-router-dom';
import noImage from 'src/assets/svg/no-image.svg';
import axios from 'src/api/axios';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AdTypeBadge from 'src/components/common/AdTypeBadge';

const ArticleList = ({ article }) => {
    const url = '/ad/' + article.id + '/' + article.slug;

    const [isFavorited, setIsFavorited] = useState(article.is_favorited);   

    const handleAddToFavorite = async () => {
        try {
            const response = await axios.post('/api/article/' + article.id + '/favorite', {
                article_id: article.id,
            });
            console.log(response);
            NotificationManager.success('Article added to favorites'); 
            if(response.data.data) {
                setIsFavorited(true);
            }
        } catch (error) {
            console.log(error);
            NotificationManager.error('Error adding article to favorites');
        }
    }

    const handleRemoveFromFavorite = async () => {
        try {
            const response = await axios.delete('/api/article/' + article.id + '/favorite');
            console.log(response);
            NotificationManager.success('Article removed from favorites'); 
            if(response.data.data) {
                setIsFavorited(false);
            }
        } catch (error) {
            console.log(error);
            NotificationManager.error('Error removing article from favorites');
        }
    }

    return (
        <div className={`flex flex-col bg-white border border-gray-200 rounded-lg shadow sm:flex-row hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 p-2 mb-4 min-h-[200px] ${article.status === 'finished' ? 'relative opacity-70' : ''}`}>
            {article.status === 'finished' && (
                <div className="absolute inset-0 bg-black bg-opacity-30 rounded-lg flex items-center justify-center z-10 pointer-events-none">
                    <span className="bg-red-600 text-white px-4 py-2 rounded-full font-bold transform rotate-[-15deg]">
                        FINISHED
                    </span>
                </div>
            )}
            <Link to={url} className='relative'>
                <img className="object-cover w-full sm:min-w-[270px] rounded-t-lg min-h-full h-auto sm:w-48 sm:rounded-lg aspect-video" src={article.featured_image_url || noImage} alt="cat" />
                <p className='absolute flex bg-[#00000070] px-2.5 py-1 rounded-md right-2 bottom-2 z-10'>
                    <img src={camera} alt="camera" />
                    <span className='ml-2 text-white font-bold'>{article.featured_image_url ? article.gallery.length + 1 : article.gallery.length}</span>
                </p>
            </Link>
            <div className="flex flex-col sm:px-4 leading-normal w-full pt-2 sm:pt-0 justify-between">
                <div>
                    <div className='flex justify-between mb-3'>
                        <div className='flex'>
                            <img src={time} alt="time" width='16' />
                            <span className='pl-1 md:pl-2'>{article.refreshed_at}</span>
                        </div>
                        <div className='flex items-center gap-3'>
                            <AdTypeBadge adType={article.ad_type} />
                            {article.is_boosted && (
                                <p className='py-1 px-2.5 text-white bg-[#E6912E] text-[12px] h-[20px] leading-[12px] rounded-sm'>BOOSTED</p>
                            )}
                            <p className='font-bold text-[18px]'>{article.price_formatted}</p>
                        </div>
                    </div>
                    <Link to={url}>
                        <h5 className="mb-1 text-2xl font-bold tracking-tight dark:text-white">{article.title}</h5>
                    </Link>
                    {(article.attributes['litter-male-count'] > 0 || article.attributes['litter-female-count'] > 0) && (
                        <p className='text-[#909090] mb-2'>
                            {article.attributes['litter-male-count'] > 0 && `${article.attributes['litter-male-count']} Male`}
                            {article.attributes['litter-male-count'] > 0 && article.attributes['litter-female-count'] > 0 && ' | '}
                            {article.attributes['litter-female-count'] > 0 && `${article.attributes['litter-female-count']} Female`}
                        </p>
                    )}
                    <p className="mb-3 font-normal text-xs md:text-sm">{article.short_description}</p>
                </div>
                <div className='flex justify-between'>
                    <div className='flex mt-auto'>
                        {article.user && (
                            <>
                                {article.user?.avatar ? (
                                    <img className="object-cover w-10 h-10 rounded-full" src={article.user.avatar} alt={article.user.profile_name} />
                                ) : (
                                    <p className='rounded-full bg-[#404040] w-10 h-10 flex'><span className='m-auto text-white font-bold text-base'>{article.user.first_name?.charAt(0) + article.user.last_name?.charAt(0)}</span></p>
                                )}
                                <div className='pl-2'>
                                    <p className='px-3 py-1 uppercase text-[#832EE6] bg-[#832EE630] text-[10px] leading-[10px] inline-block rounded-sm'>{article.user?.account_type}</p>
                                    <p className='text-sm'>
                                        <span className='font-bold mr-3'>{article.user?.profile_name}</span>
                                        {article.city && (
                                            <span className='text-[#656565] inline-block'>
                                                <img src={pin} alt="location" className='mr-1 inline-block translate-y-[-2px]' />
                                                {article.city}{article.country_name ? `, ${article.country_name}` : ''}
                                            </span>
                                        )}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                    {isFavorited ? (
                        <button className='w-10 h-10 min-w-10 rounded-full flex' onClick={handleRemoveFromFavorite}>
                            <img className='m-auto' src={heartPurpleFull} alt="heart" />
                        </button>
                    ) : (
                        <button className='w-10 h-10 min-w-10 rounded-full flex' onClick={handleAddToFavorite}>
                            <img className='m-auto' src={heart} alt="heart" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

ArticleList.propTypes = {
    article: PropTypes.shape({
        id: PropTypes.number.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }).isRequired
};

export default ArticleList
import React from 'react'
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';

const Contact = () => {
  return (
    <>
      <Header />
      <div>Contact</div>
      <Footer />
    </>
  )
}

export default Contact
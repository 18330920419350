import PropTypes from 'prop-types'
import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import axios from 'src/api/axios';
import ArticleCard from './ArticleCard';

export default class LastArticles extends PureComponent {
  static propTypes = {
    adType: PropTypes.string,
    excludeIds: PropTypes.arrayOf(PropTypes.number)
  }

  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    this.fetchLastArticles();
  }

  fetchLastArticles = async () => {
    try {
      const { adType, excludeIds } = this.props;
      let url = "/api/search/articles?per_page=8";
      
      if (adType) {
        url += `&ad_type=${adType}`;
      }
      
      if (excludeIds?.length) {
        url += `&exclude_ids=${excludeIds.join(',')}`;
      }
        
      const response = await axios.get(url);
      this.setState({ articles: response.data.data });
    } catch (error) {
      console.error("Error fetching latest articles:", error);
    }
  };

  render() {
    const { articles } = this.state;
    const { adType } = this.props;
    
    if (articles.length === 0) {
      return null;
    }

    return (
      <>
        <div className='bg-[#F0D4CD4D] py-10 sm:py-24'>
          <div className='container mx-auto px-2 flex flex-wrap gap-2 2xl:gap-[32px] justify-center'>
            <h2 className='w-full font-bold text-[25px] sm:text-[35px] pb-10 sm:pb-[60px] text-center uppercase'>
              {adType ? `Latest ${adType} Ads` : 'Latest Ads'}
            </h2>
            
            {articles.map(article => (
              <ArticleCard key={article.id} article={article} />
            ))}
            
            <div className='w-full text-center mt-10 sm:mt-[70px]'>
              <Link to="/search" className="py-2 sm:py-3.5 px-5 sm:px-10 mb-2 text-[18px] font-bold focus:outline-none rounded-full border border-black hover:border-[#832EE6] focus:z-10">
                See all posts
              </Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

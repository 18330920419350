import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'src/redux/actions/modalActions';
import { NotificationManager } from 'react-notifications';
import axios from 'src/api/axios';
import { LoginSocialGoogle } from 'reactjs-social-login';
import google from 'src/assets/svg/google.svg';

function GoogleLoginButton() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const REDIRECT_URI = window.location.origin;

  if (!process.env.REACT_APP_GG_APP_ID) {
    return (
      <div 
        className="bg-white border rounded py-3 px-6 w-full flex text-black cursor-not-allowed opacity-50 relative group"
        style={{width: '100%'}}
      >
        <img src={google} alt="google" className="w-6" />
        <span className="ml-2 font-bold text-base">Google</span>
        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-sm rounded px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
          Google login is not configured
        </div>
      </div>
    );
  }

  const handleClick = () => {
    if (isLoading) {
      window.focus();
    }
  };

  return (
    <LoginSocialGoogle
      className={`bg-white border rounded py-3 px-6 w-full flex text-black ${isLoading ? 'cursor-wait' : 'cursor-pointer'}`}
      isOnlyGetToken
      client_id={process.env.REACT_APP_GG_APP_ID}
      // redirect_uri={REDIRECT_URI}
      onResolve={async ({ provider, data }) => {
        setIsLoading(true);
        try {
          const response = await axios.post('/api/auth/callback/google', {
            provider_id: data.access_token
          });
          if (response.status === 200) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
            dispatch(hideModal());
            NotificationManager.success('Login successful');
          }
        } catch (error) {
          console.error(error);
          NotificationManager.error('Google login failed');
        } finally {
          setIsLoading(false);
        }
      }}
      onReject={(error) => {
        console.error(error);
        NotificationManager.error('Google login failed');
        setIsLoading(false);
      }}
      scope="openid profile email"
      popup={true}
      onClick={handleClick}
    >
      <div className="flex items-center justify-center w-full">
        {isLoading ? (
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-900"></div>
        ) : (
          <>
            <img src={google} alt="google" className="w-6" />
            <span className="ml-2 font-bold text-base my-auto">Google</span>
          </>
        )}
      </div>
    </LoginSocialGoogle>
  );
}

export default GoogleLoginButton;
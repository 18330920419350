import { AuthAction } from "../actions/authActions";



let user =  localStorage.getItem("user");

user = user !== null ? JSON.parse(user) : null;


const initialState = {
  isLoggedIn: user ? true : false,
  user: user ?? null,
  error: null,
};

const authReducer = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        isLoggedIn: true,
        token: action.payload.token,
        user: action.payload.user,
        error: null,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: action.payload,
      };

    case 'UPDATE_USER':
      if(action.payload) {
        localStorage.setItem('user', JSON.stringify(action.payload));
      
      return {
        ...state,
          user: action.payload
        }
      } else {
        throw new Error('Korisnik nije pronađen');
      }
    case 'LOGOUT':
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        error: null,
      };
    default:
      return state;
  }
};

export default authReducer;
import React, { useState, useEffect } from 'react'
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import logo from 'src/assets/sphynx-logo.svg'
import avatarImg from 'src/assets/svg/avatar.svg'
import heart from 'src/assets/svg/heart.svg'
import heartPurpleFull from 'src/assets/svg/heart-purple-full.svg'
import { HiChevronDown, HiPlus } from 'react-icons/hi';
import { showModal } from 'src/redux/actions/modalActions';

const Header = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [showDropdown, setShowDropdown] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        
        if (currentScrollY < lastScrollY) {
            setIsVisible(true);
        } else if (currentScrollY > lastScrollY && currentScrollY > 100) {
            setIsVisible(false);
        }
        
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollY]);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (showDropdown && !event.target.closest('.dropdown-container')) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    return (
        <>
            <nav className={`fixed top-0 left-0 right-0 bg-white z-50 transition-transform duration-300 ${isVisible ? 'translate-y-0' : '-translate-y-full'}`}>
                <div className="container mx-auto px-2 sm:px-4 py-2.5">
                    <div className="flex justify-between items-center">
                        <NavLink to={'/'} className="flex-shrink-0">
                            <img className="h-6 sm:h-8 md:h-10" src={logo} alt="Logo" />
                        </NavLink>

                        <div className="flex items-center gap-2 sm:gap-4">
                            <div className="relative flex gap-2">
                                <button 
                                    onClick={toggleDropdown}
                                    className="flex items-center"
                                >
                                    <img 
                                        className="w-8 h-8 rounded-full" 
                                        src={avatarImg} 
                                        alt="User avatar"
                                    />
                                    <HiChevronDown className='ml-2 my-auto' />
                                </button>
                                
                               

                                {showDropdown && (
                                    <div className="absolute right-0 mt-10 w-44 bg-white rounded-lg shadow-lg z-50 dropdown-container">
                                        {auth.isLoggedIn ? (
                                            <>
                                                <NavLink 
                                                    to="/my/settings" 
                                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                    My Account
                                                </NavLink>
                                                <NavLink 
                                                    to="/my/ads" 
                                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                                                    </svg>
                                                    My Adverts
                                                </NavLink>
                                                <hr className="my-1" />
                                                <NavLink 
                                                    to="/logout" 
                                                    className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                                    </svg>
                                                    Log out
                                                </NavLink>
                                            </>
                                        ) : (
                                            <>
                                                <button 
                                                    onClick={() => dispatch(showModal('LOGIN_MODAL'))}
                                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    Log in
                                                </button>
                                                <button 
                                                    onClick={() => dispatch(showModal('REGISTER_MODAL'))}
                                                    className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                                >
                                                    Sign up
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            
                            {auth.isLoggedIn && (
                                <NavLink to="/my/favorites" className="flex items-center justify-center w-8 h-8">
                                    {({ isActive }) => (
                                        <img 
                                            src={isActive ? heartPurpleFull : heart} 
                                            alt="Favorites" 
                                            className="w-5 h-5 sm:w-6 sm:h-6"
                                        />
                                    )}
                                </NavLink>
                            )}

                            <NavLink to="/post-ad">
                                <button className="flex items-center bg-[#832EE6] text-white rounded-full h-8 px-2 sm:px-3 hover:bg-[#6f26c4]">
                                    <HiPlus className="h-3 w-3 mr-1" />
                                    <span className='text-xs sm:text-sm font-bold whitespace-nowrap'>
                                        NEW AD
                                    </span>
                                </button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="h-[56px] sm:h-[64px] md:h-[72px]"></div>
        </>
    )
}

export default Header
import React, { useEffect, useState } from 'react';
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import heroBg from 'src/assets/images/coverimage.png';

import icon1 from 'src/assets/svg/icon1.svg';
import icon2 from 'src/assets/svg/icon2.svg';
import icon3 from 'src/assets/svg/icon3.svg';
import icon4 from 'src/assets/svg/icon4.svg';
import LastArticles from 'src/components/section/LastArticles';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'src/api/axios';
import BlogCard from 'src/components/blog/BlogCard';
import noImage from 'src/assets/svg/no-image.svg';

const Home = () => {
  const navigate = useNavigate();
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('/api/blog?per_page=4');
        setBlogPosts(response.data.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
       }
    };

    fetchBlogPosts();
  }, []);

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const searchParams = new URLSearchParams();

    // Dodavanje samo popunjenih polja u URL parametre
    const petType = formData.get('ad_type');
    const petAge = formData.get('age');
    const petGender = formData.get('gender');

    if (petType) searchParams.append('ad_type', petType);
    if (petAge) searchParams.append('age', petAge);
    if (petGender) searchParams.append('gender', petGender);

    // Navigacija na search stranu sa parametrima
    navigate(`/search?${searchParams.toString()}`);
  };

  return (
    <>
      <Header />
      <div className='container mx-auto px-2 flex flex-col justify-end lg:justify-center relative min-h-[680px] sm:min-h-[750px] mt-10'>
        <img src={heroBg} alt="bg" className='absolute right-0 z-[-1] top-0 md:top-[unset] min-w-[400px]' />
        <div className='max-w-[620px]'>
          <h1 className='text-[30px] leading-[40px] sm:text-[42px] sm:leading-[52px] md:text-[58px] md:leading-[68px]'>
            <b>Sphynx cats</b><br /> deserve loving homes
          </h1>
          <p className='text-sm sm:text-base mt-2 sm:mt-6'>
            We help you find the perfect Sphynx cat, with special care dedicated to their health and happiness.
          </p>
        </div>
        <form onSubmit={handleSearchSubmit}>
          <div className='flex flex-wrap gap-2 shadow-[0_2px_12px_rgba(65,37,103,0.16)] rounded-md sm:rounded-full max-w-[720px] bg-white mt-8 lg:mt-14 p-3'>
            <div
              id="select"
              className='my-auto sm:w-[unset] font-bold flex-1'
            >
              <select
                name='ad_type'
                className='hero-select bg-transparent'
              >
                <option value=''>
                  ADVERT TYPE
                </option>
                <option value='for-sale'>
                  For sale
                </option>
                <option value='for-adoption'>
                  For adoption
                </option>
                <option value='for-stud'>
                  For stud
                </option>
                <option value='wanted'>
                  Wanted
                </option>
              </select>
            </div>
            <div
              id="select" 
              className='my-auto sm:w-[unset] font-bold flex-1'
            >
              <select
                name='age'
                className='hero-select bg-transparent'
              >
                <option value=''>
                  AGE
                </option>
                <option value='2-4-months'>
                  0-4 Months
                </option>
                <option value='5-12-months'>
                  5-12 Months
                </option>
                <option value='1-2-years'>
                  1-2 Years
                </option>
                <option value='3-5-years'>
                  3-5 Years
                </option>
                <option value='older-than-5-years'>
                  5+ Years
                </option>
              </select>
            </div>
            <div
              id="select"
              className='my-auto sm:w-[unset] font-bold flex-1'
            >
              <select
                name='gender'
                className='hero-select bg-transparent'
              >
                <option value=''>
                  GENDER
                </option>
                <option value='male'>
                  Male
                </option>
                <option value='female'>
                  Female
                </option>
              </select>
            </div>
            <button 
              type="submit"
              className="text-white m-auto sm:mr-0 sm:ml-auto font-bold bg-[#832EE6] hover:bg-[#6f26c4] focus:outline-none rounded-full text-sm w-full sm:w-[155px] px-5 py-2.5 text-center"
            >
              Search
            </button>
          </div>
        </form>
      </div>
      <LastArticles/>
      <div className='bg-white py-10 sm:py-24'>
        <div className='container mx-auto px-2 flex flex-wrap gap-[15px] 2xl:gap-[32px] justify-between'>
          <h2 className='w-full font-bold text-[25px] sm:text-[35px] pb-10 sm:pb-[60px] text-center uppercase'>
            What you need to know before buying
          </h2>
          <div className='flex flex-col max-w-full md:max-w-[47%]'>
            <div className='flex gap-3 sm:gap-6 items-center'>
              <img src={icon1} alt="icon" className='mb-auto w-[50px] sm:w-[95px]' />
              <h3 className='font-bold text-xl sm:text-2xl uppercase'>Microchipped</h3>
            </div>
            <div className='sm:ml-[118px] mt-2 sm:mt-1'>
              <p className='text-sm sm:text-base pb-5'>
                Microchipping is a quick and safe procedure where a small chip containing the owner’s contact details is implanted under the cat’s skin. This ensures that lost cats can be easily returned to their rightful owners, as the information is stored in a secure database. Although microchipping is not mandatory everywhere, it is a responsible practice that enhances the safety of your pet. Before purchasing, confirm with the seller whether the cat has been microchipped and ask for the associated documentation.              </p>
            </div>
          </div>
          <div className='flex flex-col max-w-full md:max-w-[47%]'>
            <div className='flex gap-3 sm:gap-6 items-center'>
              <img src={icon2} alt="icon" className='mb-auto w-[50px] sm:w-[95px]' />
              <h3 className='font-bold text-xl sm:text-2xl uppercase'>Vaccinations Up-to-Date</h3>
            </div>
            <div className='sm:ml-[118px] mt-2 sm:mt-1'>
              <p className='text-sm sm:text-base pb-5'>
                Vaccinations are essential for protecting cats from serious diseases and ensuring their long-term health. Regular vaccinations, as recommended by veterinarians, prevent common feline illnesses and keep your cat safe. When purchasing a cat, request their vaccination records and consult a vet to verify if additional vaccinations are needed. A vaccinated cat is healthier and reduces risks to other pets in your home.              </p>
            </div>
          </div>
          <div className='flex flex-col max-w-full md:max-w-[47%]'>
            <div className='flex gap-3 sm:gap-6 items-center'>
              <img src={icon3} alt="icon" className='mb-auto w-[50px] sm:w-[95px]' />
              <h3 className='font-bold text-xl sm:text-2xl uppercase'>Neutered</h3>
            </div>
            <div className='sm:ml-[118px] mt-2 sm:mt-1'>
              <p className='text-sm sm:text-base pb-5'>
                Neutering, which involves spaying for females or castration for males, is a procedure that prevents unwanted reproduction and offers several health benefits. It reduces the risk of reproductive cancers and helps control the stray cat population. If you are not planning to breed your cat, it is advisable to check with the seller whether the cat has already been neutered or arrange to have the procedure done by your veterinarian.              </p>
            </div>
          </div>
          <div className='flex flex-col max-w-full md:max-w-[47%]'>
            <div className='flex gap-3 sm:gap-6 items-center'>
              <img src={icon4} alt="icon" className='mb-auto w-[50px] sm:w-[95px]' />
              <h3 className='font-bold text-xl sm:text-2xl uppercase'>Registered</h3>
            </div>
            <div className='sm:ml-[118px] mt-2 sm:mt-1'>
              <p className='text-sm sm:text-base pb-5'>
                Registered cats come with pedigree certificates issued by recognized organizations such as TICA (The International Cat Association), GCCF (Governing Council of the Cat Fancy), or FIFe (Fédération Internationale Féline). These certificates confirm the cat’s lineage and authenticity. Before making a purchase, ask for a copy of the pedigree certificate and verify its validity. Keep in mind that unregistered cats may lack traceable ancestry, which could be important for breeding purposes.              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#F7F7F7] py-16 md:py-24'>
        <div className='container mx-auto px-2'>
          <h2 className='w-full font-bold text-[25px] sm:text-[35px] pb-10 sm:pb-[60px] text-center uppercase'>
            Blog
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
              {blogPosts.map((post, index) => (
                <Link 
                  to={`/blog/${post.slug}`} 
                  key={post.id} 
                  className={`relative overflow-hidden rounded-lg group ${
                    index === 0 ? 'md:col-span-2 md:row-span-2' : 
                    index === 1 ? 'md:col-span-2' :
                    'col-span-1'
                  }`}
                >
                  <img 
                    src={post.featured_image || noImage} 
                    alt={post.title}
                    className={`w-full object-cover transition-transform duration-300 group-hover:scale-105 ${
                      index === 0 ? 'h-[300px] md:h-[632px]' : 'h-[200px] md:h-[300px]'
                    }`}
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/95 via-black/70 to-transparent p-4 md:p-6">
                    <h2 className={`text-white font-bold mb-2 group-hover:text-[#832EE6] transition-colors drop-shadow-lg ${
                      index === 0 ? 'text-xl md:text-2xl' : 'text-lg md:text-xl'
                    }`}>{post.title}</h2>
                    <p className="text-white drop-shadow-md line-clamp-2 text-sm md:text-base">{post.excerpt}</p>
                    <div className="mt-2 md:mt-4 text-white drop-shadow-md text-xs md:text-sm">{post.created_at}</div>
                  </div>
                </Link>
              ))}
            </div>

          <div className='w-full text-center mt-[70px]'>
            <Link to="/blog" className="py-2 sm:py-3.5 px-5 sm:px-10 mb-2 text-[18px] font-bold focus:outline-none rounded-full border border-black hover:border-[#832EE6] focus:z-10">
              See all articles
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
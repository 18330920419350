import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'src/api/axios';
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import BlogCard from 'src/components/blog/BlogCard';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [suggestedPosts, setSuggestedPosts] = useState([]);
  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`/api/blog/${slug}`);
        setPost(response.data.data);
      } catch (error) {
        console.error('Error fetching blog post:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  const fetchSuggestedPosts = async () => {
    const response = await axios.get(`/api/blog?per_page=4&order_by=random`);
    setSuggestedPosts(response.data.data);
  };

  useEffect(() => {
    fetchSuggestedPosts();
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <div className="container mx-auto px-2 py-8">
          <article className="max-w-4xl mx-auto">
            <div className="animate-pulse">
              <div className="h-12 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"></div>
              <div className="h-[400px] bg-gray-200 rounded-lg mb-8"></div>
              <div className="space-y-4">
                <div className="h-4 bg-gray-200 rounded w-full"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                <div className="h-4 bg-gray-200 rounded w-4/6"></div>
                <div className="h-4 bg-gray-200 rounded w-full"></div>
                <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              </div>
            </div>
          </article>
        </div>
        <div className="animate-pulse bg-[#F7F7F7] py-16 md:py-24">
          <div className="container mx-auto px-2">
            <div className="h-8 bg-gray-200 rounded w-64 mx-auto mb-12"></div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-[15px] 2xl:gap-[32px]">
              {[...Array(4)].map((_, i) => (
                <div key={i} className="h-[300px] bg-gray-200 rounded-lg"></div>
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (!post) {
    return (
      <>
        <Header />
        <div className="container mx-auto px-2 py-8">
          <h1 className="text-2xl font-bold">Blog post not found</h1>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="container mx-auto px-2 py-8">
        <article className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
          <div className="text-gray-600 mb-8">
            <time dateTime={post.created_at}>
              {post.created_at}
            </time>
          </div>
          {post.featured_image && (
            <img 
              src={post.featured_image} 
              alt={post.title}
              className="w-full h-auto rounded-lg mb-8 object-cover"
            />
          )}
          <div 
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </article>

      </div>
      {suggestedPosts.length > 0 && (
      <div className='bg-[#F7F7F7] py-16 md:py-24'>
        <div className='container mx-auto px-2 flex flex-wrap gap-[15px] 2xl:gap-[32px] justify-center'>
          <h2 className='w-full font-bold text-[25px] sm:text-[35px] pb-10 sm:pb-[60px] text-center uppercase'>
            More articles
          </h2>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-[15px] 2xl:gap-[32px]">
                {suggestedPosts.map(post => (
                  <BlogCard key={post.id} post={post} />
                ))}
              </div>
        </div>
      </div>
      )}
      <Footer />
    </>
  );
};

export default BlogPost;

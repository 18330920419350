import React, { useState, useEffect, useCallback } from 'react'
import Header from 'src/layouts/Header'
import Footer from 'src/layouts/Footer'
import InputCounter from 'src/components/InputCounter'
import ColorBox from 'src/components/ColorBox'
import ImageUpload from 'src/components/ImageUpload'
import MultipleImageUpload from 'src/components/MultipleImageUpload'
import axios from 'src/api/axios'
import { NotificationManager } from 'react-notifications'
import { useNavigate } from 'react-router-dom'

const PostAd = () => {
  const navigate = useNavigate()
  const [errors, setErrors] = useState({})
  const [formData, setFormData] = useState({
    ad_type: 'for-sale',
    currency: 'EUR',
  })
  const [adOptions, setAdOptions] = useState({})

  const fetchAdOptions = useCallback(async () => {
    try {
      const response = await axios.get(`/api/resources/post-ad?ad_type=${formData.ad_type}`)
      setAdOptions(response.data.data)
    } catch (error) {
      NotificationManager.error('Error fetching ad options')
    }
  }, [formData.ad_type])

  useEffect(() => {
    fetchAdOptions()
  }, [fetchAdOptions])

  const handleChange = useCallback(event => {
    const { name, value } = event.target
    setFormData(prevFormData => {
      if (name.endsWith('[]')) {
        const arrayName = name.slice(0, -2)
        const currentArray = prevFormData[arrayName] || []

        if (event.target.checked) {
          setErrors(prev => ({ ...prev, [arrayName]: [] }))
          return { ...prevFormData, [arrayName]: [...currentArray, value] }
        } else {
          setErrors(prev => ({ ...prev, [arrayName]: [] }))
          return { ...prevFormData, [arrayName]: currentArray.filter(item => item !== value) }
        }
      }
      setErrors(prev => ({ ...prev, [name]: [] }))
      return { ...prevFormData, [name]: value }
    })
  }, [])

  const handleImageChange = useCallback((data) => {
    handleChange({ target: { name: 'featured_image_url', value: data?.public_url ?? '' } })
    handleChange({ target: { name: 'featured_image', value: data?.path ?? '' } })
  }, [])

  const handleGalleryChange = useCallback((data) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      gallery: data.map(item => ({
        id: item.id,
        name: item.name,
        public_url: item.url,
        original_url: item.url,
        preview_url: item.url,
        isNew: item.isNew,
        path: item.isNew ? item.path : undefined
      }))
    }))
  }, [])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault()
    
    try {
      const response = await axios.post('/api/post-ad', formData)
      if (response.status === 201) {
        NotificationManager.success(response.data.message)
        const articleUrl = `/ad/${response.data.data.id}/${response.data.data.slug}`
        navigate(articleUrl)
      }
    } catch (error) {
      if (error.response?.status === 422) {
        setErrors(error.response.data.errors)
        const firstErrorField = Object.keys(error.response.data.errors)[0]
        const element = document.querySelector(`[name="${firstErrorField}"]`)
        if (element) element.focus()
      }
      NotificationManager.error(error.response?.data?.message || 'Error creating ad')
    }
  }, [formData, navigate])

  return (
    <>
      <Header />
      <div className='bg-[#F7F7F7] py-[20px] md:py-[35px] min-h-screen'>
        <div className='container mx-auto px-4 flex justify-center'>
          <div className="max-w-[953px] w-full py-[15px] md:py-[25px] bg-white border border-[#EBEBEB] rounded-lg mb-5" style={{ boxShadow: '0px 3px 6px #8D8D8D36' }}>
            <p className='my-auto uppercase font-bold text-[20px] md:text-[24px] leading-7 px-4 md:px-6 lg:px-10 pb-[15px] md:pb-[25px]'>Post an Ad</p>
            <hr />
            <form onSubmit={handleSubmit}>
              <div className='px-4 md:px-6 lg:px-10 pt-5 pb-2.5'>
                <p className="font-bold text-[15px] md:text-[17px] leading-6 pb-[25px] md:pb-[38px]">Choose an ad type</p>
                <div className="flex flex-col md:flex-row gap-4 mb-9">
                  {adOptions.ad_types && Object.entries(adOptions.ad_types).map(([key, value]) => (
                    <div key={key} className='relative flex w-full'>
                      <input
                        name="ad_type"
                        checked={formData.ad_type === key}
                        onChange={handleChange}
                        id={`radioType${key}`}
                        type="radio"
                        value={key}
                        className="peer w-4 h-4 rounded accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 absolute top-1/2 -translate-y-1/2 left-5"
                      />
                      <label
                        htmlFor={`radioType${key}`}
                        className="py-3 px-5 rounded-lg cursor-pointer w-full border border-white hover:border-[#832EE6] hover:border peer-checked:!shadow-none peer-checked:border-[#832EE6]"
                        style={{ boxShadow: '0px 3px 6px #00000029' }}
                      >
                        <span className="text-base font-medium text-[#0D0D0D] dark:text-gray-300 pl-8">{value}</span>
                      </label>
                    </div>
                  ))}
                </div>
                {formData.ad_type === 'for-stud' && (
                  <>
                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                      <p>All options below refer to your cat. Fill in the fields related to your cat. Special breeding requirements should be filled in the "Special Breeding Requirements" field.</p>
                    </div>
                  </>
                )}
                {adOptions['age'] && (
                  <div className='flex flex-col md:flex-row flex-wrap justify-between gap-2 mb-11 w-full'>
                    {adOptions['age']?.options && Object.entries(adOptions['age'].options).map(([key, value]) => (
                      <div className="flex items-center" key={key}>
                        <input 
                          checked={formData.age?.includes(key)} 
                          name="age[]" 
                          id={`checkboxAge${key}`} 
                          onChange={handleChange} 
                          type="checkbox" 
                          value={key} 
                          className="w-4 h-4 accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" 
                        />
                        <label htmlFor={`checkboxAge${key}`} className="ml-2 text-base font-medium text-[#0D0D0D] dark:text-gray-300">{value}</label>
                      </div>
                    ))}
                  </div>
                )}
                <hr />
                <p className="font-bold text-[15px] md:text-[17px] leading-6 pb-[25px] md:pb-[38px] pt-[25px] md:pt-[36px]">Information about your pet:</p>
                <div className='flex flex-col md:flex-row justify-between gap-6 md:gap-1 mb-11'>
                  <div className='w-full md:max-w-[420px]'>
                    <div>
                      <label htmlFor="title" className="block mb-2 text-base font-medium text-[#0D0D0D] dark:text-white">Title</label>
                      <input 
                        name="title" 
                        value={formData.title || ''} 
                        onChange={handleChange} 
                        type="text" 
                        id="title" 
                        className="max-h-[36px] bg-white border border-gray-300 text-[#0D0D0D] text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                      />
                    </div>
                    <div htmlFor="price" className="block mt-5 mb-2 text-base font-medium text-[#0D0D0D] dark:text-white">Price</div>
                    <div className="flex items-center">
                      <div className="relative max-w-[180px]">
                        <input 
                          name="price" 
                          value={formData.price || ''} 
                          onChange={handleChange} 
                          type="text" 
                          id="price" 
                          disabled={formData.price_negotiable} 
                          placeholder={formData.price_negotiable ? 'Negotiable' : 'Price'}
                          className="max-h-[36px] pr-[60px] bg-white text-left border border-gray-300 text-[#0D0D0D] text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        />
                        <div className='absolute right-0 top-0'>
                          <select 
                            name="currency" 
                            value={formData.currency || ''} 
                            onChange={handleChange} 
                            id="currency" 
                            disabled={formData.price_negotiable}
                            className="font-bold max-h-[36px] bg-white border border-gray-300 text-gray-900 text-base border-l-0 rounded-r border-s-gray-100 dark:border-s-gray-700 border-s-2 focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          >
                            {adOptions.currencies && Object.entries(adOptions.currencies).map(([key, value]) => (
                              <option key={key} value={key}>{value}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          handleChange({
                            target: {
                              name: 'price_negotiable',
                              value: !formData.price_negotiable,
                              type: 'checkbox'
                            }
                          });
                          handleChange({
                            target: {
                              name: 'price',
                              value: '',
                              type: 'text'
                            }
                          });
                        }}
                        className={`ml-2 px-2 h-[36px] text-sm rounded border-2 border-purple-600 ${
                          formData.price_negotiable 
                            ? 'bg-purple-600 text-white' 
                            : 'bg-white text-gray-700'
                        } hover:bg-purple-700 hover:border-purple-700 hover:text-white transition-colors`}
                      >
                        Negotiable
                      </button>
                    </div>
                    {adOptions['color'] && (
                      <div className="mt-7 mb-2 text-base font-medium text-[#0D0D0D] dark:text-white">
                        Color
                        <div className="grid grid-cols-2 gap-2 -mt-2 max-w-[250px] pt-2">
                          {adOptions.color.options && Object.entries(adOptions.color.options).map(([key, value]) => (
                            <ColorBox 
                              key={key} 
                              name="color[]" 
                              type="checkbox" 
                              value={key} 
                              spanText={value} 
                              onChange={handleChange} 
                              checked={formData.color?.includes(key)}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                    {adOptions['date-of-birth'] && (
                      <>
                        <label htmlFor="date-of-birth" className="block mt-5 mb-2 text-base font-medium text-[#0D0D0D] dark:text-white">Date of Birth</label>
                        <input 
                          name="date-of-birth" 
                          value={formData['date-of-birth'] || ''} 
                          onChange={handleChange} 
                          type="date" 
                          id="date-of-birth" 
                          max={new Date(new Date().setMonth(new Date().getMonth() - 2)).toISOString().split('T')[0]}
                          className="max-h-[36px] max-w-[180px] bg-white border border-gray-300 text-[#0D0D0D] text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        />
                      </>
                    )}
                  </div>
                  <div className='w-full md:max-w-[380px]'>
                    {adOptions['litter-male-count'] && (
                      <>
                        <label htmlFor="litter-male-count" className="block text-base font-medium text-[#0D0D0D] dark:text-white">
                          Pets in litter
                        </label>
                        <div className="space-y-4 mt-2">
                          <div className="flex items-center">
                            <span className="text-base font-medium text-[#777777] w-[70px]">Male</span>
                            <div className="flex items-center">
                              <InputCounter 
                                name="litter-male-count"
                                value={formData['litter-male-count']}
                                onChange={handleChange}
                                step={1}
                              />
                            </div>
                          </div>
                          {adOptions['litter-female-count'] && (
                            <div className="flex items-center">
                              <span className="text-base font-medium text-[#777777] w-[70px]">Female</span>
                              <div className="flex items-center">
                                <InputCounter
                                  name="litter-female-count" 
                                  value={formData['litter-female-count']}
                                  onChange={handleChange}
                                  step={1}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {adOptions['training-level'] && (
                      <>
                        <label htmlFor="training-level" className="block text-base font-medium text-[#0D0D0D] dark:text-white mt-5 mb-2">Training level</label>
                        <select 
                          id="training-level" 
                          name="training-level" 
                          value={formData['training-level'] || ''} 
                          onChange={handleChange} 
                          className="bg-white border max-h-[36px] border-gray-300 text-[#777777] text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full h-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="">Select training level</option>
                          {adOptions['training-level']?.options && Object.entries(adOptions['training-level'].options).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                          ))}
                        </select>
                      </>
                    )}
                    {adOptions['energy-level'] && (
                      <>
                        <label htmlFor="energy-level" className="block text-base font-medium text-[#0D0D0D] dark:text-white mt-7 mb-2">Energy level</label>
                        <select 
                          id="energy-level" 
                          name="energy-level" 
                          value={formData['energy-level'] || ''} 
                          onChange={handleChange} 
                          className="bg-white border max-h-[36px] border-gray-300 text-[#777777] text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full h-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="">Select energy level</option>
                          {adOptions['energy-level']?.options && Object.entries(adOptions['energy-level'].options).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                          ))}
                        </select>
                      </>
                    )}
                    {adOptions['grooming-level'] && (
                      <>
                        <label htmlFor="grooming-level" className="block text-base font-medium text-[#0D0D0D] dark:text-white mt-7 mb-2">Grooming level</label>
                        <select 
                          id="grooming-level" 
                          name="grooming-level" 
                          value={formData['grooming-level'] || ''} 
                          onChange={handleChange} 
                          className="bg-white border max-h-[36px] border-gray-300 text-[#777777] text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full h-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="">Select grooming level</option>
                          {adOptions['grooming-level']?.options && Object.entries(adOptions['grooming-level'].options).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>
                </div>

                {adOptions['health-and-documentation'] && (
                  <>
                    <hr />
                    <p className="font-bold text-[15px] md:text-[17px] leading-6 pb-[25px] md:pb-[33px] pt-[25px] md:pt-[36px]">
                      Cat health & Documentation
                    </p>
                    <ul className="space-y-5 mb-10">
                      {adOptions['health-and-documentation']?.options &&
                        Object.entries(adOptions['health-and-documentation'].options).map(
                          ([key, value], index) => (
                            <li key={index}>
                              <div className="flex items-center mr-4">
                                <input
                                  name="health-and-documentation[]"
                                  onChange={handleChange}
                                  id={`health-checkbox-${index}`}
                                  type="checkbox"
                                  value={key}
                                  checked={formData['health-and-documentation']?.includes(key)}
                                  className="w-4 h-4 accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                  htmlFor={`health-checkbox-${index}`}
                                  className="ml-2 text-base font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {value}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                    </ul>
                  </>
                )}

                <hr />
                <p className="font-bold text-[15px] md:text-[17px] leading-6 pb-[25px] md:pb-10 pt-[25px] md:pt-[36px]">Upload media</p>
                {formData.ad_type === 'wanted' && (
                  <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
                    <p>Here you can upload how you imagine your future cat, this is optional.</p>
                  </div>
                )}
                <div className="flex flex-col md:flex-row gap-6 md:gap-10 mb-10">
                  <ImageUpload onImageChange={handleImageChange} />
                  <div className="hidden md:block w-[1px] bg-[#DEDEDE]"></div>
                  <MultipleImageUpload onImagesChange={handleGalleryChange} />
                </div>

                <hr />
                {(adOptions.temperament || adOptions.compatibility) && (
                  <>
                    <p className="font-bold text-[15px] md:text-[17px] leading-6 pb-[25px] md:pb-[33px] pt-[25px] md:pt-[36px]">Pet Features:</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-2 mb-10">
                      {adOptions.temperament && (
                        <div>
                          <p className="uppercase text-base mb-6">Temperament</p>
                          <ul className="space-y-5 mb-10">
                            {adOptions.temperament?.options && Object.entries(adOptions.temperament.options).map(([key, value], index) => (
                              <li key={index}>
                                <div className="flex items-center mr-4">
                                  <input 
                                    name="temperament[]" 
                                    onChange={handleChange} 
                                    id={`temperament-checkbox-${index}`} 
                                    type="checkbox" 
                                    value={key} 
                                    checked={formData.temperament?.includes(key)}
                                    className="w-4 h-4 accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" 
                                  />
                                  <label htmlFor={`temperament-checkbox-${index}`} className="ml-2 text-base font-medium text-gray-900 dark:text-gray-300">{value}</label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {adOptions.compatibility && (
                        <div>
                          <p className="uppercase text-base mb-6">Compatibility</p>
                          <ul className="space-y-5 mb-10">
                            {adOptions.compatibility?.options && Object.entries(adOptions.compatibility.options).map(([key, value], index) => (
                              <li key={index}>
                                <div className="flex items-center mr-4">
                                  <input 
                                    name="compatibility[]" 
                                    onChange={handleChange} 
                                    id={`compatibility-checkbox-${index}`} 
                                    type="checkbox" 
                                    value={key} 
                                    checked={formData.compatibility?.includes(key)}
                                    className="w-4 h-4 accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" 
                                  />
                                  <label htmlFor={`compatibility-checkbox-${index}`} className="ml-2 text-base font-medium text-gray-900 dark:text-gray-300">{value}</label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </>
                )}

                <hr />
                <p className="font-bold text-[17px] leading-6 pb-[23px] pt-[36px]">More about your pet:</p>
                <textarea 
                  name="description" 
                  value={formData.description || ''} 
                  onChange={handleChange} 
                  id="description" 
                  rows="8" 
                  className="mb-9 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                ></textarea>

                {adOptions['breeding-requirements'] && (
                  <>
                    <hr />
                    <p className="font-bold text-[17px] leading-6 pb-[23px] pt-[36px]">Special Breeding Requirements:</p>
                    <textarea 
                      name="breeding-requirements" 
                      value={formData['breeding-requirements'] || ''} 
                      onChange={handleChange}
                      id="breeding-requirements" 
                      rows="4"
                      className="mb-9 block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    ></textarea>
                  </>
                )}

                <div className="flex justify-between">
                  <button 
                    type="submit" 
                    className="focus:outline-none min-w-[113px] text-white font-bold bg-[#832EE6] hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 rounded-[19px] text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900 max-h-[37px]"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PostAd
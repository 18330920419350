import { ModalAction } from '../actions/modalActions';

export interface ModalState {
  activeModal: string | null;
  modalProps: any;
}

const initialState: ModalState = {
  activeModal: null,
  modalProps: {}
};

const modalReducer = (state = initialState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        ...state,
        activeModal: action.payload.modalType,
        modalProps: action.payload.modalProps || {}
      };
      
    case 'HIDE_MODAL':
      return {
        ...state,
        activeModal: null,
        modalProps: {}
      };
      
    default:
      return state;
  }
};

export default modalReducer;
import React, { useState, useEffect } from 'react'
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import { useParams } from "react-router-dom";
import { NotificationManager } from 'react-notifications';
import axios from 'src/api/axios';
import LastArticles from 'src/components/section/LastArticles';
import { HiChevronDown } from 'react-icons/hi';
import UserBox from 'src/components/UserBox';
import ImageGallery from "react-image-gallery";
import { Link } from 'react-router-dom';
import AdFavoriteButton from 'src/components/ad/AdFavoriteButton';

import pin from 'src/assets/svg/location.svg';
import eye from 'src/assets/svg/eye.svg';
import heart from 'src/assets/svg/heart.svg';
import time from 'src/assets/svg/time.svg';
import reminder from 'src/assets/svg/reminder.svg';
import edit from 'src/assets/svg/edit.svg';
import refresh from 'src/assets/svg/refresh.svg';
import ReportModal from 'src/components/modals/ReportModal';
import moment from 'moment';
import AdTypeBadge from 'src/components/common/AdTypeBadge';


const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [gallery, setGallery] = useState([]);

  const calculateAge = (dateOfBirth, adType, ages) => {
    if (adType === 'wanted') {
      if (!ages?.length) return '-';

      // Check if all options are selected
      if (ages.length === 5) return 'Any age';

      // Sort ages by their minimum value
      const sortOrder = {
        '2-4-months': 1,
        '5-12-months': 2,
        '1-2-years': 3,
        '3-5-years': 4,
        'older-than-5-years': 5
      };
      const sortedAges = [...ages].sort((a, b) => sortOrder[a] - sortOrder[b]);

      // Check for continuous ranges
      const has24Months = sortedAges.includes('2-4-months');
      const has512Months = sortedAges.includes('5-12-months');
      const has12Years = sortedAges.includes('1-2-years');
      const has35Years = sortedAges.includes('3-5-years');

      // Only combine ranges if they're actually adjacent
      if (has24Months && has512Months && has12Years && has35Years) {
        return '2 months to 5 years';
      }
      if (has512Months && has12Years && has35Years) {
        return '5 months to 5 years';
      }
      if (has24Months && has512Months && has12Years) {
        return '2 months to 2 years';
      }
      if (has512Months && has12Years) {
        return '5 months to 2 years';
      }
      if (has24Months && has512Months) {
        return '2-12 months';
      }
      if (has12Years && has35Years) {
        return '1-5 years';
      }

      // For non-continuous combinations, list them separately
      const ageMap = {
        '2-4-months': '2-4 months',
        '5-12-months': '5-12 months',
        '1-2-years': '1-2 years',
        '3-5-years': '3-5 years',
        'older-than-5-years': 'Older than 5 years'
      };

      return sortedAges.map(age => ageMap[age]).join(', ');
    }

    // For non-wanted ads, keep existing logic
    if (!dateOfBirth) return '-';

    const years = moment().diff(moment(dateOfBirth), 'years');
    const months = moment().diff(moment(dateOfBirth), 'months') % 12;
    const days = moment().diff(moment(dateOfBirth), 'days') % 30;

    const parts = [];
    if (years > 0) parts.push(`${years} years,`);
    if (months > 0) parts.push(`${months} months,`);
    if (days > 0) parts.push(`${days} days`);

    return parts.length > 0 ? parts.join(' ') : '-';
  };

  const formatRange = (ranges) => {
    if (ranges.length === 0) return '';
    
    if (ranges.length === 1) {
      const ageMap = {
        '2-4-months': '2-4 months',
        '5-12-months': '5-12 months',
        '1-2-years': '1-2 years',
        '3-5-years': '3-5 years',
        'older-than-5-years': 'Older than 5 years'
      };
      return ageMap[ranges[0]];
    }

    // Combine adjacent ranges
    if (ranges.includes('2-4-months') && ranges.includes('5-12-months')) {
      return '2-12 months';
    }
    if (ranges.includes('1-2-years') && ranges.includes('3-5-years')) {
      return '1-5 years';
    }

    return ranges.map(r => formatRange([r])).join(', ');
  };

  useEffect(() => {
    if (article) {
      document.title = article.title + ' | SphynxPlace';
    } else {
      document.title = 'Article not found | SphynxPlace';
    }
  }, [article]);

  useEffect(() => {
    if (article) {
      const galleryImages = [];
      
      if (article.gallery) {
        Object.values(article.gallery).forEach(image => {
          galleryImages.push({
            original: image.original_url,
            thumbnail: image.original_url
          });
        });
      }

      if (article.featured_image_url) {
        galleryImages.unshift({
          original: article.featured_image_url,
          thumbnail: article.featured_image_url
        });
      }

      setGallery(galleryImages);
    }
  }, [article]);

  const handleRefresh = async () => {
    try {
      const response = await axios.post(`api/article/${id}/refresh`);
      NotificationManager.success(response.data.message ?? 'Article successfully refreshed');
      setArticle(prevArticle => ({
        ...prevArticle,
        ...response.data.data
      }));
    } catch (error) {
      NotificationManager.error(error.response?.data?.message ?? 'Failed to refresh article');
    }
  }

  const handleFinish = async () => {
    try {
      const response = await axios.post(`api/article/${id}/finish`);
      if (response.status === 200) {
        NotificationManager.success(response.data.message ?? 'Article successfully finished');
        setArticle(prevArticle => ({
          ...prevArticle,
          ...response.data.data
        }));
      } else {
        NotificationManager.error(response.data.message ?? 'Failed to finish article');
      }
    } catch (error) {
      NotificationManager.error(error.response?.data?.message ?? 'Failed to finish article');
    }
  }

  const handleActivate = async () => {
    try {
      const response = await axios.post(`api/article/${id}/activate`);
      NotificationManager.success(response.data.message ?? 'Article successfully activated');
      setArticle(prevArticle => ({
        ...prevArticle,
        ...response.data.data
      }));
    } catch (error) {
      NotificationManager.error(error.response?.data?.message ?? 'Failed to activate article');
    }
  }

  useEffect(() => {
    const getArticleDetails = async () => {
      try {
        const response = await axios.get('api/article/' + id);
        setArticle(prevArticle => ({
          ...prevArticle,
          ...response.data.data
        }));
      } catch (error) {
        console.error(error);
        if (!error.response) {
          NotificationManager.error('No server response');
        } else if (error.response?.status === 401) {
          NotificationManager.error('Unauthorized');
        } else {
          NotificationManager.error(error.message);
          if (error.response.data.message) {
            NotificationManager.error(error.response.data.message);
          }
          console.log(error);
        }
      }
    }

    getArticleDetails();

  }, [id]);

  if (!article) {
    return (
      <>
        <Header />
        <div className='bg-[#F7F7F7] py-[20px] md:py-[35px]'>
          <div className='container mx-auto px-4 flex flex-col md:flex-row gap-5 justify-between'>
            <div className="w-full bg-white rounded-[10px] p-4 md:p-10 shadow-md">
              <div className="animate-pulse">
                <div className='flex relative mt-4 mb-3 gap-2 justify-between'>
                  <div className="h-10 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-10 w-10 bg-gray-200 rounded-full"></div>
                </div>

                <div className='flex flex-wrap gap-2 md:gap-0 mb-6'>
                  {[...Array(4)].map((_, i) => (
                    <div key={i} className='h-6 bg-gray-200 rounded w-24 mr-6'></div>
                  ))}
                </div>

                <div className="h-[400px] bg-gray-200 rounded-lg mb-8"></div>

                {[...Array(3)].map((_, i) => (
                  <div key={i} className="mb-8">
                    <div className="h-8 bg-gray-200 rounded w-48 mb-4"></div>
                    <div className="space-y-4">
                      {[...Array(4)].map((_, j) => (
                        <div key={j} className="h-4 bg-gray-200 rounded w-full"></div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full md:max-w-[332px]">
              <div className="animate-pulse">
                <div className='hidden sm:block h-20 bg-gray-200 rounded-lg mb-6'></div>
                <div className='bg-white border border-[#EBEBEB] rounded-lg p-5'>
                  <div className="h-32 bg-gray-200 rounded mb-4"></div>
                  <div className="h-6 bg-gray-200 rounded w-1/2 mx-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LastArticles />
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      {article ? (
        <>
        <div className='bg-[#F7F7F7] py-[20px] md:py-[35px]'>
          <div className='container mx-auto px-2 flex flex-col md:flex-row gap-5 justify-between'>
            <div className="w-full bg-white rounded-[10px] p-4 md:p-10 shadow-md">
              <div>
                <div>
                  <div className='flex relative mt-4 mb-3 gap-2 justify-between'>
                    <h2 className="text-[24px] md:text-[35px] leading-[30px] md:leading-[45px] text-purplesp-50">
                      {article.title}
                    </h2>

                    <AdFavoriteButton 
                      adId={article.id}
                      isFavorited={article.is_favorited}
                      onUpdate={setArticle}
                    />

                    <div className='absolute top-[-25px] right-0'>
                      {article.is_boosted && <span className="mt-1 md:mt-0 md:ml-2 h-fit rounded-sm bg-[#E6912E] font-bold py-0.5 px-2.5 text-white text-[13px] md:text-[15px] leading-[22px] uppercase">Boosted</span>}
                      {article.status !== 'published' && <span className="mt-1 md:mt-0 md:ml-2 h-fit rounded-sm bg-[#00000070] font-bold py-0.5 px-2.5 text-white text-[13px] md:text-[15px] leading-[22px] uppercase">{article.status}</span>}
                    </div>
                  </div>

                  <div className='flex flex-wrap gap-2 md:gap-0'>
                    <p className='flex mr-4 md:mr-6'><img src={pin} alt="location" className='h-[18px] my-auto' /> <span className='my-auto ml-1.5 font-bold text-sm md:text-base'>{article.city || article.country_name ? `${article.city || ''}${article.city && article.country_name ? ', ' : ''}${article.country_name || ''}` : 'Not specified'}</span></p>
                    <p className='flex mr-4 md:mr-6'><img src={time} alt="time" className='h-[18px] my-auto' /><span className='my-auto ml-1.5 text-[#0D0D0D] text-sm md:text-base'>{article.refreshed_at}</span></p>
                    <p className='flex mr-4 md:mr-6'><img src={eye} alt="location" className='h-[15px] my-auto' /> <span className='my-auto ml-1.5 text-sm md:text-base'>{article.views}</span></p>
                    <p className='flex mr-4 md:mr-6'><img src={heart} alt="time" className='h-[18px] my-auto' /><span className='my-auto ml-1.5 text-[#0D0D0D] text-sm md:text-base'>{article.favorites_count}</span></p>
                  </div>
                </div>
              </div>

              {gallery && gallery.length > 0 && (
                <div className="mt-4">
                  <ImageGallery
                    items={gallery}
                    showPlayButton={false}
                    showFullscreenButton={true}
                    showNav={true}
                    showThumbnails={true}
                    showIndex={true}
                    thumbnailPosition="bottom"
                    slideDuration={300}
                    slideInterval={3000}
                    infinite={true}
                    lazyLoad={true}
                    onClick={() => {
                      document.querySelector('.image-gallery-fullscreen-button').click();
                    }}
                  />
                </div>
              )}
              <div className='flex flex-wrap sm:hidden p-4 md:p-6 rounded-lg bg-[#443F4B] mb-4 md:mb-6 items-center justify-center gap-2'>
                <AdTypeBadge adType={article.ad_type} />
                <div className="flex items-center gap-2 w-auto justify-center whitespace-nowrap">
                  <span className='text-white text-[20px] md:text-[24px] leading-[26px] md:leading-[30px]'>Price:</span>
                  <span className='text-white text-[20px] md:text-[24px] leading-[26px] md:leading-[30px] font-bold'>{article.price_formatted}</span>
                </div>
              </div>

              <div className='mt-6 md:mt-10 pb-6 border-b-[1px] border-b-[#DEDEDE]'>
                <h3 className='text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] pb-4 md:pb-6'>Cat Health & Documentation</h3>
                <div className='flex flex-wrap gap-x-4 gap-y-2'>
                  <div className='flex w-full md:w-[calc(50%-8px)]'>
                    <div className='text-[16px] md:text-[18px] min-w-[60%] font-bold'>Health Checked</div>
                    <div className='text-[16px] md:text-[18px] min-w-[80px]'>
                      {article.attributes?.['health-and-documentation']?.includes('fife') && 'Fife\n'}
                      {article.attributes?.['health-and-documentation']?.includes('gccf') && 'GCCF\n'} 
                      {article.attributes?.['health-and-documentation']?.includes('tica') && 'Tica\n'}
                      {!article.attributes?.['health-and-documentation']?.includes('fife') &&
                       !article.attributes?.['health-and-documentation']?.includes('gccf') &&
                       !article.attributes?.['health-and-documentation']?.includes('tica') &&
                       (article.ad_type === 'wanted' ? 'Not required' : (article.attributes?.['health-and-documentation']?.includes('health-checked') ? 'Yes' : 'No'))}
                    </div>
                  </div>
                  <div className='flex w-full md:w-[calc(50%-8px)]'>
                    <div className='text-[16px] md:text-[18px] min-w-[60%] font-bold'>Is Microchipped</div>
                    <div className='text-[16px] md:text-[18px] min-w-[80px]'>
                      {article.attributes?.['health-and-documentation']?.includes('microchipped') ? 'Yes' : (article.ad_type === 'wanted' ? 'Not required' : 'No')}
                    </div>
                  </div>
                  <div className='flex w-full md:w-[calc(50%-8px)]'>
                    <div className='text-[16px] md:text-[18px] min-w-[60%] font-bold'>Is Neutered</div>
                    <div className='text-[16px] md:text-[18px] min-w-[80px]'>
                      {article.attributes?.['health-and-documentation']?.includes('neutered') ? 'Yes' : 'No'}
                    </div>
                  </div>
                  <div className='flex w-full md:w-[calc(50%-8px)]'>
                    <div className='text-[16px] md:text-[18px] min-w-[60%] font-bold'>Is Vaccinated</div>
                    <div className='text-[16px] md:text-[18px] min-w-[80px]'>
                      {article.attributes?.['health-and-documentation']?.includes('vaccinated') ? 'Yes' : (article.ad_type === 'wanted' ? 'Not required' : 'No')}
                    </div>
                  </div>
                  <div className='flex w-full md:w-[calc(50%-8px)]'>
                    <div className='text-[16px] md:text-[18px] min-w-[60%] font-bold'>Is Worm Treated</div>
                    <div className='text-[16px] md:text-[18px] min-w-[80px]'>
                      {article.attributes?.['health-and-documentation']?.includes('is-worm-treated') ? 'Yes' : (article.ad_type === 'wanted' ? 'Not required' : 'No')}
                    </div>
                  </div>
                  <div className='flex w-full md:w-[calc(50%-8px)]'>
                    <div className='text-[16px] md:text-[18px] min-w-[60%] font-bold'>Pet Age</div>
                    <div className='text-[16px] md:text-[18px] min-w-[80px]'>
                      {calculateAge(
                        article.attributes?.['date-of-birth'],
                        article.ad_type,
                        article.attributes?.age
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-6 md:mt-10 pb-6 border-b-[1px] border-b-[#DEDEDE]'>
                <h3 className='text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] pb-4 md:pb-6'>Pet Features</h3>
                <div className='flex flex-col gap-y-2'>
                  <div className='flex flex-col sm:flex-row w-full'>
                    <div className='text-[16px] md:text-[18px] min-w-[30%] font-bold'>Color</div>
                    <div className='text-[16px] md:text-[18px]'>
                      {article.attributes?.color?.join(', ') || 'Not specified'}
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row w-full'>
                    <div className='text-[16px] md:text-[18px] min-w-[30%] font-bold'>Gender</div>
                    <div className='text-[16px] md:text-[18px]'>
                      {article.attributes?.['litter-male-count'] || article.attributes?.['litter-female-count']
                        ? [
                            article.attributes['litter-male-count'] > 0 && `${article.attributes['litter-male-count']} Male`,
                            article.attributes['litter-female-count'] > 0 && `${article.attributes['litter-female-count']} Female`
                          ].filter(Boolean).join(', ')
                        : 'Not specified'}
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row w-full'>
                    <div className='text-[16px] md:text-[18px] min-w-[30%] font-bold'>Temperament</div>
                    <div className='text-[16px] md:text-[18px]'>
                      {article.attributes?.temperament?.join(', ') || 'Not specified'}
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row w-full'>
                    <div className='text-[16px] md:text-[18px] min-w-[30%] font-bold'>Compatibility</div>
                    <div className='text-[16px] md:text-[18px]'>
                      {article.attributes?.compatibility?.map(item => {
                        const labels = {
                          'okay-with-cats': 'cats',
                          'okay-with-dogs': 'dogs',
                          'okay-with-kids': 'kids',
                          'okay-with-seniors': 'seniors', 
                          'okay-with-apartments': 'apartments'
                        };
                        return labels[item];
                      }).join(', ') || 'Not specified'}
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-6 md:mt-10 pb-6 border-b-[1px] border-b-[#DEDEDE]'>
                <h3 className='text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] pb-4 md:pb-6'>Description</h3>
                <p className='text-sm md:text-base'>{article.description}</p>
              </div>
              {article.ad_type === 'for-stud' && article.attributes?.['breeding-requirements'] && (
                <div className='mt-6 md:mt-10 pb-6 border-b-[1px] border-b-[#DEDEDE]'>
                  <h3 className='text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] pb-4 md:pb-6'>Special Breeding Requirements</h3>
                  <p className='text-sm md:text-base'>{article.attributes['breeding-requirements']}</p>
                </div>
              )}


              <div className='mt-6 md:mt-10 pb-6 border-b-[1px] border-b-[#DEDEDE]'>
                <div className="flex items-start gap-2 p-4 bg-[#FFF7E7] rounded-lg">
                  <div className="text-[#F6A609] text-xl">⚠️</div>
                  <div className="text-sm md:text-base">
                    <span className="font-bold text-[#F6A609] mb-1">Disclaimer: </span>
                    Sphynxplace.com does not guarantee the accuracy of ads, the legitimacy of sellers, or the safety of payments. Buyers are fully responsible for verifying all information and ensuring secure transactions before making any payment.
                  </div>
                </div>
              </div>


              <div className="mt-6 md:mt-10 pb-6 border-b-[1px] border-b-[#DEDEDE]">
                <h3 className='text-[20px] md:text-[24px] leading-[30px] md:leading-[35px] flex mb-4'>Important Reminders <img src={reminder} alt="reminder" className='ml-1' /></h3>
                <div className="text-sm md:text-base">
                  <p className="mb-2">Exercise caution with online transactions! <br /> Take the time to review these essential tips before making a decision to buy or adopt a Sphynx cat.</p>

                  <p className="mb-2 font-bold">Have You Researched Thoroughly?</p>
                  <p className="mb-2">Before committing to a purchase, educate yourself about the breed. Owning a cat is a significant responsibility, so make sure you understand its needs, temperament, and whether it fits your lifestyle and budget.</p>

                  <p className="mb-2 font-bold">Have you met the kitten in person?</p>
                  <p className="mb-2">Whenever possible, visit the seller's location to see the kitten and verify the living conditions. Avoid relying only on online messages and photos. If the seller is unwilling to meet in person or continuously avoids providing accurate details, treat this as a red flag. As an alternative, consider requesting a live video call to observe the kitten and its environment.</p>

                  <p className="mb-2 font-bold">Avoid unsecured payments</p>
                  <p className="mb-2">Do not send money through unverified methods, especially without personally inspecting the kitten and meeting the seller. This includes deposits or delivery charges. Always use secure payment methods with buyer protection and ensure you have a signed agreement before proceeding.</p>

                  <p className="mb-2 font-bold">Check the documents and health records</p>
                  <p className="mb-2">Ask for all relevant documentation, such as pedigree certificates (from recognized organizations like TICA, GCCF, or FIFe) and veterinary health records. Ensure that the kitten's vaccinations are up-to-date and request evidence of deworming and flea treatments. Trustworthy sellers will also address health concerns or provide refunds if a vet confirms poor health.</p>

                  <p className="mb-2 font-bold">A responsible seller's commitment</p>
                  <p className="mb-2">Kittens should only be rehomed once they are at least 8 weeks old. Taking them away earlier can lead to health and behavioral problems. Verify that the kitten has been well cared for and has had sufficient time with its mother before adoption.</p>
                </div>
              </div>
            </div>

            <div className="w-full md:max-w-[332px]">
              <div className='hidden sm:flex flex-wrap p-4 md:p-6 rounded-lg bg-[#443F4B] mb-4 md:mb-6 items-center justify-center gap-2'>
                <AdTypeBadge adType={article.ad_type} />
                <div className="flex items-center gap-2 w-full sm:w-auto mt-2 sm:mt-0 justify-center">
                  <span className='text-white text-[20px] md:text-[24px] leading-[26px] md:leading-[30px]'>Price:</span>
                  <span className='text-white text-[20px] md:text-[24px] leading-[26px] md:leading-[30px] font-bold'>{article.price_formatted}</span>
                </div>
              </div>

              <div className='bg-white border border-[#EBEBEB] rounded-lg p-4 md:p-5'>
                <UserBox user={article.user} />
                <div className='text-center mt-3'>
                  <ReportModal type="ad" />
                </div>
              </div>

              {article.can_edit && (
                <>
                  <div className='flex gap-2.5 my-4'>
                    <Link to={`/my/ad/${article.id}/edit`} className='w-full flex py-2.5 md:py-3 px-4 md:px-5 rounded-[10px] bg-white border border-[#CBCBCB] justify-center hover:bg-[#832EE61C] transition'>
                      <img src={edit} alt="edit" className='my-auto h-[13px]' />
                      <span className='text-sm md:text-base my-auto ml-2'>Edit AD</span>
                    </Link>
                    <button onClick={handleRefresh} className='w-full flex py-2.5 md:py-3 px-4 md:px-5 rounded-[10px] bg-white border border-[#CBCBCB] justify-center hover:bg-[#832EE61C] transition'>
                      <img src={refresh} alt="edit" className='my-auto h-[13px]' />
                      <span className='text-sm md:text-base my-auto ml-2'>Refresh AD</span>
                    </button>
                  </div>

                  {article.status === 'published' && (
                    <button onClick={handleFinish} className='w-full flex py-2.5 md:py-3 px-4 md:px-5 rounded-[10px] bg-white border border-[#CBCBCB] justify-center hover:bg-[#832EE61C] transition'>
                      <span className='text-sm md:text-base my-auto ml-2'>Finish AD</span>
                    </button>
                  )}
                  {article.status === 'finished' && (
                    <button onClick={handleActivate} className='w-full flex py-2.5 md:py-3 px-4 md:px-5 rounded-[10px] bg-white border border-[#CBCBCB] justify-center hover:bg-[#832EE61C] transition'>
                      <img src={refresh} alt="refresh" className='my-auto h-[13px]' />
                      <span className='text-sm md:text-base my-auto ml-2'>Activate AD again</span>
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          </div>
          <LastArticles key={article.id} adType={article.ad_type} excludeIds={[article.id]} />
        </>
      ) : (
        <>
          <div className="container mx-auto px-4 flex flex-wrap gap-[15px] 2xl:gap-[32px] justify-center">
            <h2 className="w-full font-bold text-[24px] md:text-[35px] pb-[30px] md:pb-[60px] text-center uppercase">404 - Article not found</h2>
          </div>
        </>
      )}

      <Footer />
    </>
  )
}

export default Article
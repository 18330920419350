import React, { useState, useEffect } from "react";
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import ArticleList from 'src/components/section/ArticleList';
import axios from 'src/api/axios';
import { useSelector } from 'react-redux';
import noFavorites from 'src/assets/svg/nofavourites.svg';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pagination from 'src/components/common/Pagination';

const Favorites = () => {
    const { user } = useSelector(state => state.auth);
    const [articles, setArticles] = useState([]);
    const [meta, setMeta] = useState({});
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();



    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                setLoading(true);
                const params = Object.fromEntries(
                    Array.from(searchParams.entries()).filter(([key]) => 
                        ['sort_by', 'page'].includes(key)
                    )
                );

                const { data } = await axios.get('api/my/favorites', { params });
                
                setArticles(data.data);
                setMeta(data.meta);
            } catch (error) {
                console.error('Error fetching favorites:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFavorites();
    }, [searchParams]);

    const handleSortChange = (e) => {
        const value = e.target.value;
        if (value) {
            setSearchParams({ sort_by: value });
        } else {
            setSearchParams({});
        }
    };

    const handlePageChange = (page) => {
        setSearchParams(prev => ({...prev, page}));
    };

    return (
        <>
            <Header />
            <div className='bg-[#F7F7F7] py-5 md:py-[35px] min-h-screen'>
                <div className='container mx-auto px-2 flex flex-col'>
                    <div className="max-w-[1000px] m-auto w-full">
                        <div className='flex flex-col md:flex-row gap-3 md:gap-[15px] 2xl:gap-[32px] justify-between mb-5'>
                            <h2 className='text-center md:text-left uppercase font-bold text-[20px] md:text-[24px] leading-[28px] md:leading-[35px]'>My favourite Sphynx</h2>
                            <select 
                                id="sort" 
                                value={searchParams.get('sort_by') || ''}
                                onChange={handleSortChange}
                                className="bg-gray-50 border border-gray-300 text-sm md:text-base block w-full md:w-[170px] p-2.5 rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="">Sort by</option>
                                <option value="favorite_date">Date added to favorites</option>
                                <option value="price_asc">Price (low to high)</option>
                                <option value="price_desc">Price (high to low)</option>
                                <option value="publish_date">Publish date</option>
                            </select>
                        </div>
                        {loading ? (
                            <div className="flex items-center justify-center py-6 md:py-10">
                                <div className="w-8 h-8 md:w-12 md:h-12 border-3 md:border-4 border-purple-600 rounded-full animate-spin border-t-transparent"></div>
                            </div>
                        ) : articles.length > 0 ? (
                            <>
                                {articles.map((article) => (
                                    <ArticleList key={article.id} article={article} />
                                ))}
                                {meta && meta?.total > 0 && meta?.last_page > 1 && <Pagination meta={meta} onPageChange={handlePageChange} />}
                            </>

                        ) : (
                            <div className='flex flex-col'>
                                <img src={noFavorites} alt="nofavorites" className="mx-auto mt-5 md:mt-10 w-[200px] md:w-auto"/>
                                <h2 className="uppercase font-bold text-[20px] md:text-[24px] leading-[28px] md:leading-[35px] my-[30px] md:my-[50px] text-center">No favorites here yet</h2>
                                <Link to="/search" className="bg-[#832EE6] hover:bg-purple-800 text-white px-[30px] md:px-[45px] mx-auto py-[12px] md:py-[16px] text-[16px] md:text-[18px] leading-5 md:leading-6 font-bold inline-block rounded-[27px]">Find a Sphynx</Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Favorites
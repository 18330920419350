import React from 'react';
import { useSelector } from 'react-redux';
import LoginModal from './modals/LoginModal';
import RegisterModal from './modals/RegisterModal';
import ForgotPasswordModal from './modals/ForgotPasswordModal';
const ModalManager = () => {
  const activeModal = useSelector((state) => state.modal.activeModal);
  const modalProps = useSelector((state) => state.modal.modalProps);

  const renderModal = () => {
    switch (activeModal) {
      case 'LOGIN_MODAL':
        return <LoginModal {...modalProps} />;
      case 'REGISTER_MODAL':
        return <RegisterModal {...modalProps} />;
      case 'FORGOT_PASSWORD_MODAL':
        return <ForgotPasswordModal {...modalProps} />;
      default:
        throw new Error(`Invalid modal type: ${activeModal}`);
    }
  };

  return activeModal ? (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[1000]">
      {renderModal()}
    </div>
  ) : null;
};

export default ModalManager;
import React, { useState } from 'react';

const QuantityInput = ({ name, value, onChange, step = 1, min = 0, max = 100, required = false }) => {
  const [quantity, setQuantity] = useState(value ? Number(value) : '');
  
  const decrement = () => {
    const newQuantity = Math.max((quantity ?? min + 1) - 1, min);
    setQuantity(newQuantity);
    onChange({ target: { name, value: newQuantity } });
  };

  const increment = () => {
    const newQuantity = Math.min(Number(quantity ?? min) + 1, max);
    setQuantity(newQuantity);
    onChange({ target: { name, value: newQuantity } });
  };

  const handleInputChange = (event) => {
    const inputQuantity = parseInt(event.target.value, 10);
    const boundedQuantity = Math.min(Math.max(isNaN(inputQuantity) ? min : inputQuantity, min), max);
    setQuantity(boundedQuantity);
    onChange({ target: { name, value: boundedQuantity } });
  };

  return (
    <div className="relative flex items-center max-w-[160px] max-h-[36px] border border-gray-300 rounded-md">
      <button
        type="button"
        onClick={decrement}
        className="bg-[#832EE6] hover:bg-[#5e19af] rounded-l flex px-3 h-[36px] focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none min-w-[36px]"
      >
        <span className='m-auto font-bold text-base text-white'>-</span>
      </button>
      <input
        name={name}
        type="text"
        value={quantity}
        onChange={handleInputChange}
        className="bg-white h-[34px] text-center max-h-[36px] text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder=""
        required={required}
        inputMode="numeric"
        pattern="[0-9]*"
      />
      <button
        type="button"
        onClick={increment}
        className="bg-[#832EE6] hover:bg-[#5e19af]  rounded-r flex px-3 h-[36px] focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none min-w-[36px]"
      >
       <span className='m-auto font-bold text-base text-white'>+</span>
      </button>
    </div>
  );
};

export default QuantityInput;

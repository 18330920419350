import BlogCard from 'src/components/blog/BlogCard';
import axios from 'src/api/axios';
import { useEffect, useState } from 'react';
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import noImage from 'src/assets/svg/no-image.svg';

const BlogList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [meta, setMeta] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const query = searchParams.get('q') || '';
    setSearchTerm(query);
    
    const fetchBlogPosts = async () => {
      try {
        const params = new URLSearchParams();
        params.append('per_page', '4');
        if (query) {
          params.append('search', query);
        }
        
        const response = await axios.get(`/api/blog?${params.toString()}`);
        setBlogPosts(response.data.data);
        setMeta(response.data.meta);
      } catch (error) {
        console.error('Error loading blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, [searchParams]);

  const handlePageChange = async (page) => {
    try {
      const params = new URLSearchParams();
      params.append('page', page);
      params.append('per_page', '4');
      if (searchTerm) {
        params.append('search', searchTerm);
      }

      const response = await axios.get(`/api/blog?${params.toString()}`);
      setBlogPosts(prevPosts => [...prevPosts, ...response.data.data]);
      setMeta(response.data.meta);
    } catch (error) {
      console.error('Error fetching page:', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchTerm) {
      setSearchParams({ q: searchTerm });
    } else {
      setSearchParams({});
    }
  };

  return (
    <>
      <Header />
      <div className="bg-[#832EE6] py-8">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold text-center mb-8 text-white">Blog</h1>
          
          <form onSubmit={handleSearch} className="max-w-xl mx-auto mb-8 flex gap-2">
            <input
              type="text"
              placeholder="Search blog posts..."
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 bg-white"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button 
              type="submit"
              className="px-6 py-2 bg-white text-[#832EE6] rounded-lg hover:bg-gray-100 font-semibold"
            >
              Search
            </button>
          </form>
        </div>
      </div>

      <div className="container mx-auto px-2 py-8">
        {blogPosts.length > 0 && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-8 md:mb-12">
              {blogPosts.slice(0, 4).map((post, index) => (
                <Link 
                  to={`/blog/${post.slug}`} 
                  key={post.id} 
                  className={`relative overflow-hidden rounded-lg group ${
                    index === 0 ? 'md:col-span-2 md:row-span-2' : 
                    index === 1 ? 'md:col-span-2' :
                    'col-span-1'
                  }`}
                >
                  <img 
                    src={post.featured_image || noImage} 
                    alt={post.title}
                    className={`w-full object-cover transition-transform duration-300 group-hover:scale-105 ${
                      index === 0 ? 'h-[300px] md:h-[632px]' : 'h-[200px] md:h-[300px]'
                    }`}
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/95 via-black/70 to-transparent p-4 md:p-6">
                    <h2 className={`text-white font-bold mb-2 group-hover:text-[#832EE6] transition-colors drop-shadow-lg ${
                      index === 0 ? 'text-xl md:text-2xl' : 'text-lg md:text-xl'
                    }`}>{post.title}</h2>
                    <p className="text-white drop-shadow-md line-clamp-2 text-sm md:text-base">{post.excerpt}</p>
                    <div className="mt-2 md:mt-4 text-white drop-shadow-md text-xs md:text-sm">{post.created_at}</div>
                  </div>
                </Link>
              ))}
            </div>

            {blogPosts.length > 4 && (
              <div className="grid grid-cols-2 lg:grid-cols-4 gap-[15px] 2xl:gap-[32px]">
                {blogPosts.slice(4).map(post => (
                  <BlogCard key={post.id} post={post} />
                ))}
              </div>
            )}
          </>
        )}

        {blogPosts.length === 0 && (
          <div className='flex flex-col'>
            <h2 className="uppercase font-bold text-[20px] md:text-[24px] leading-[28px] md:leading-[35px] my-[30px] md:my-[50px] text-center">
              No articles found matching your search
            </h2>
            <Link to="/blog" className="bg-[#832EE6] hover:bg-purple-800 text-white px-[30px] md:px-[45px] mx-auto py-[12px] md:py-[16px] text-[16px] md:text-[18px] leading-5 md:leading-6 font-bold inline-block rounded-[27px]">
              View all articles
            </Link>
          </div>
        )}

        {meta && meta.current_page < meta.last_page && (
          <div className="text-center mt-8">
            <button
              onClick={() => handlePageChange(meta.current_page + 1)}
              className="py-2 sm:py-3.5 px-5 sm:px-10 mb-2 text-[18px] font-bold focus:outline-none rounded-full border border-black hover:text-blue-700 focus:z-10"
            >
              Load more
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}; 

export default BlogList;
import { React, useState } from 'react';

import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import RegisterForm from 'src/components/form/RegisterForm';

const Register = () => {
  return (
    <>
      <Header />
      <RegisterForm />
      <Footer />
    </>
  );
};

export default Register;
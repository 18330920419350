import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    if (!cookies.cookieConsent) {
      setIsVisible(true);
    }
  }, [cookies.cookieConsent]);

  const acceptAllCookies = () => {
    setCookie('cookieConsent', { ...preferences, analytics: true, marketing: true }, 
      { path: '/', maxAge: 31536000 });
    setIsVisible(false);
  };

  const acceptNecessaryOnly = () => {
    setCookie('cookieConsent', { 
      necessary: true,
      analytics: false,
      marketing: false 
    }, { path: '/', maxAge: 31536000 });
    setIsVisible(false);
  };

  const savePreferences = () => {
    setCookie('cookieConsent', preferences, { path: '/', maxAge: 31536000 });
    setIsVisible(false);
  };

  const handleToggle = (category) => {
    if (category === 'necessary') return; // Ne može se isključiti
    setPreferences(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-black/90 text-white p-4 z-50 max-h-[90vh] overflow-y-auto">
      <div className="max-w-7xl mx-auto flex flex-col gap-4">
        {!showDetails ? (
          // Responsive view with right-aligned buttons on desktop
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div className="sm:flex-1">
              <p className="text-sm">
                This website uses cookies to enhance your browsing experience. 
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-3 sm:items-center">
              <div className="flex gap-2 text-sm order-2 sm:order-1">
                <button 
                  onClick={acceptNecessaryOnly}
                  className="flex-1 sm:flex-none px-3 py-2 bg-gray-700 text-gray-100 rounded-md hover:bg-gray-600 transition-colors duration-200 whitespace-nowrap"
                >
                  Necessary Only
                </button>
                <button 
                  onClick={() => setShowDetails(true)}
                  className="flex-1 sm:flex-none px-3 py-2 bg-gray-700 text-gray-100 rounded-md hover:bg-gray-600 transition-colors duration-200 whitespace-nowrap"
                >
                  Customize
                </button>
              </div>
              <button 
                onClick={acceptAllCookies}
                className="w-full sm:w-auto order-1 sm:order-2 px-6 py-3 bg-green-600 text-white text-base font-semibold rounded-md hover:bg-green-700 transition-colors duration-200 shadow-lg whitespace-nowrap"
              >
                Accept All Cookies
              </button>
            </div>
          </div>
        ) : (
          // Detailed view - optimized for mobile
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between items-center border-b border-gray-700 pb-4">
              <h3 className="text-lg font-bold">Cookie Settings</h3>
              <button 
                onClick={() => setShowDetails(false)}
                className="p-2 text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>
            
            <p className="text-sm">
              We respect your privacy. Choose which types of cookies you want to accept.
            </p>

            <div className="space-y-3">
              {/* Cookie options with better touch targets */}
              <div className="p-4 bg-gray-800 rounded-lg">
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <h4 className="font-semibold mb-1">Necessary Cookies</h4>
                    <p className="text-sm text-gray-300">Required for the website to function properly.</p>
                  </div>
                  <div className="pt-1">
                    <input type="checkbox" checked disabled className="h-6 w-6" />
                  </div>
                </div>
              </div>

              <div className="p-4 bg-gray-800 rounded-lg">
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <h4 className="font-semibold mb-1">Analytics Cookies</h4>
                    <p className="text-sm text-gray-300">Help us understand how you use our website.</p>
                  </div>
                  <div className="pt-1">
                    <input 
                      type="checkbox" 
                      checked={preferences.analytics}
                      onChange={() => handleToggle('analytics')}
                      className="h-6 w-6"
                    />
                  </div>
                </div>
              </div>

              <div className="p-4 bg-gray-800 rounded-lg">
                <div className="flex items-start justify-between gap-4">
                  <div className="flex-1">
                    <h4 className="font-semibold mb-1">Marketing Cookies</h4>
                    <p className="text-sm text-gray-300">Used for personalized advertising.</p>
                  </div>
                  <div className="pt-1">
                    <input 
                      type="checkbox" 
                      checked={preferences.marketing}
                      onChange={() => handleToggle('marketing')}
                      className="h-6 w-6"
                    />
                  </div>
                </div>
              </div>
            </div>

            <a 
              href="/privacy-policy" 
              className="block text-blue-400 hover:text-blue-300 text-sm underline py-2"
            >
              Learn more in our Privacy Policy
            </a>

            <div className="flex flex-col gap-2 pt-2">
              <button 
                onClick={savePreferences}
                className="w-full px-4 py-3 bg-blue-600 text-white text-base font-medium rounded-md hover:bg-blue-700 transition-colors duration-200"
              >
                Save Preferences
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CookieConsent;
import React from 'react';
import footerfacebook from '../assets/svg/footerfacebook.svg';
import footerinstagram from '../assets/svg/footerinstagram.svg';
import footerpinterest from '../assets/svg/footerpinterest.svg';
import footertwitter from '../assets/svg/footertwitter.svg';
import footeryoutube from '../assets/svg/footeryoutube.svg';

const Footer = () => {
  return (
    <>
      <footer className="bg-[#443F4B]">
        <div className="container mx-auto">
          <div className="px-2 py-6 flex flex-col items-center space-y-4 sm:space-y-0 sm:flex-row sm:justify-between">
            <div className="flex flex-col sm:flex-row items-center gap-2 text-center sm:text-left">
              <span className="text-sm text-white">© Copyright 2025 - Sphynx</span>
              <span className="text-sm text-white hidden sm:block">|</span>
              <a href="https://laracode.net" target="_blank" rel="noopener noreferrer" className="text-sm text-white hover:text-gray-300">
                Web Development by Laracode.net
              </a>
            </div>
            <div className="flex justify-center space-x-4 sm:space-x-6">
              <a href="#" className="text-white hover:text-gray-300 p-2">
                <img src={footerfacebook} alt="footerfacebook" className="w-6 h-6" />
                <span className="sr-only">Facebook page</span>
              </a>
              <a href="#" className="text-white hover:text-gray-300 p-2">
                <img src={footertwitter} alt="footertwitter" className="w-6 h-6" />
                <span className="sr-only">Twitter account</span>
              </a>
              <a href="#" className="text-white hover:text-gray-300 p-2">
                <img src={footerinstagram} alt="footerinstagram" className="w-6 h-6" />
                <span className="sr-only">Instagram page</span>
              </a>
              <a href="#" className="text-white hover:text-gray-300 p-2">
                <img src={footeryoutube} alt="footeryoutube" className="w-6 h-6" />
                <span className="sr-only">Youtube account</span>
              </a>
              <a href="#" className="text-white hover:text-gray-300 p-2">
                <img src={footerpinterest} alt="footerpinterest" className="w-6 h-6" />
                <span className="sr-only">Pinterest page</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import axios from 'src/api/axios';
import { NotificationManager } from 'react-notifications';
import pin from 'src/assets/svg/location.svg';
import noImage from 'src/assets/svg/no-image.svg';
import heart from 'src/assets/svg/heart.svg';
import heartPurpleFull from 'src/assets/svg/heart-purple-full.svg';
import time from 'src/assets/svg/time.svg';

const ArticleCard = ({ article }) => {
    const url = '/ad/' + article.id + '/' + article.slug;

    const [isFavorited, setIsFavorited] = useState(article.is_favorited);   

    const handleAddToFavorite = async () => {
        try {
            const response = await axios.post('/api/article/' + article.id + '/favorite', {
                article_id: article.id,
            });
            NotificationManager.success('Artikal dodat u omiljene');
            if(response.data.data) {
                setIsFavorited(true);
            }
        } catch (error) {
            NotificationManager.error('Greška pri dodavanju u omiljene');
        }
    }

    const handleRemoveFromFavorite = async () => {
        try {
            const response = await axios.delete('/api/article/' + article.id + '/favorite');
            NotificationManager.success('Artikal uklonjen iz omiljenih');
            if(response.data.data) {
                setIsFavorited(false);
            }
        } catch (error) {
            NotificationManager.error('Greška pri uklanjanju iz omiljenih');
        }
    }

  return (
    <div className="flex flex-col justify-evenly w-full xxs:w-[calc(50%-15px)] sm:w-[264px] lg:w-[340px] bg-white border border-gray-200 rounded-lg shadow">
      <div className='p-1 sm:p-4 flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <img src={time} alt="time" className='w-4 h-4 sm:w-5 sm:h-5' />
          <span className='text-sm sm:text-base'>{article.refreshed_at}</span>
        </div>
      </div>
      <Link to={url} className="block">
        <img className="px-1 sm:px-5 lg:px-10 pb-3 sm:pb-5 w-full h-auto aspect-video	object-cover" src={article.featured_image_url || noImage} alt={article.title} />
      </Link>
      <div className="px-1 sm:px-4 pb-3 sm:pb-4">
        <Link to={url}>
          <h5 className="text-base sm:text-2xl text-center font-bold tracking-tight line-clamp-2">{article.title}</h5>
        </Link>
        {(article.city || article.country_name) && (
          <p className='flex justify-center mt-2 mb-4 sm:mb-6'>
            <img src={pin} alt="location" className='w-4 h-4 sm:w-5 sm:h-5' /> 
            <span className='text-[#656565] text-xs sm:text-base ml-2'>
              {article.city && article.country_name ? `${article.city}, ${article.country_name}` : article.city || article.country_name}
            </span>
          </p>
        )}
        <div className="flex items-center justify-between">
          <span className="text-sm sm:text-base leading-[16px] text-white rounded-2xl px-3 sm:px-4 py-1.5 sm:py-2 bg-[#832EE6]">{article.price_formatted}</span>
          <button 
            className='w-8 h-8 sm:w-10 sm:h-10 min-w-8 sm:min-w-10 rounded-full flex items-center justify-center touch-manipulation'
            onClick={isFavorited ? handleRemoveFromFavorite : handleAddToFavorite}
          >
            <img className='w-5 h-5 sm:w-6 sm:h-6' src={isFavorited ? heartPurpleFull : heart} alt="heart" />
          </button>
        </div>
      </div>
    </div>
  );
};

ArticleCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    refreshed_at: PropTypes.string.isRequired,
    city: PropTypes.string,
    country_name: PropTypes.string,
    price_formatted: PropTypes.string.isRequired,
    featured_image_url: PropTypes.string || PropTypes.null,
    is_favorited: PropTypes.bool
  }).isRequired
};

export default ArticleCard;
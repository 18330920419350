import React, { useCallback, useState } from 'react';
import axios from 'src/api/axios';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from 'src/redux/actions/modalActions';
import FacebookLoginButton from 'src/components/form/social-buttons/FacebookLoginButton';
import GoogleLoginButton from 'src/components/form/social-buttons/GoogleLoginButton';

function RegisterForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms_and_conditions: false
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('api/register', {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        password_confirmation: formData.password_confirmation,
        terms_and_conditions: formData.terms_and_conditions
      });
      
      dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
      dispatch(hideModal());
      NotificationManager.success('Registration successful');
      navigate('/');
    } catch (error) {
      console.error(error);
      if (!error.response) {
        NotificationManager.error('No server reponse');
      } else if (error.response?.status === 400) {
        NotificationManager.error('Missing required fields');
      } else if (error.response?.status === 401) {
        for (const [key, value] of Object.entries(error.response.data.error)) {
          console.log(`${key}: ${value}`);
          NotificationManager.error(`${key}: ${value}`);
        }
        NotificationManager.error('Unauthorized');
      } else {
        NotificationManager.error(error.message);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  return (
    <>
      <div className="w-full mx-auto bg-white sm:max-w-md md:max-w-[950px]">
        <div className="mb-10">
          <h2 className="text-[35px] leading-[45px] font-bold text-center">
            Sign up
          </h2>
          <p className="text-base mt-4 mb-6 text-center">
            Already have an account?{' '}
            <span
              onClick={() => dispatch(showModal('LOGIN_MODAL'))}
              className="font-bold text-[#832EE6] cursor-pointer"
            >
              Login
            </span>
          </p>
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex w-full mb-4 items-center">
              <label htmlFor="first_name" className="w-fit min-w-[120px] text-sm font-semibold text-gray-700 hidden sm:block">
                Full name
              </label>
              <div className="w-full flex gap-4">
                <div className="flex-1">
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] placeholder-[#0D0D0D] block w-full p-4"
                    placeholder="First name"
                    required=""
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Last name"
                    className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] placeholder-[#0D0D0D] block w-full p-4"
                    required=""
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full mb-4 items-center">
              <label htmlFor="email" className="w-fit min-w-[120px] text-sm font-semibold text-gray-700 hidden sm:block">
                Email
              </label>
              <div className="w-full">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] placeholder-[#0D0D0D] block w-full p-4"
                  required=""
                />
              </div>
            </div>

            <div className="flex w-full mb-4 items-center">
              <label htmlFor="password" className="w-fit min-w-[120px] text-sm font-semibold text-gray-700 hidden sm:block">
                Password
              </label>
              <div className="w-full flex gap-4">
                <div className="flex-1">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                    className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] placeholder-[#0D0D0D] block w-full p-4"
                    required=""
                  />
                </div>
                <div className="flex-1">
                  <input
                    type="password"
                    id="password_confirmation"
                    name="password_confirmation"
                    value={formData.password_confirmation}
                    onChange={handleChange}
                    placeholder="Confirm password"
                    className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] placeholder-[#0D0D0D] block w-full p-4"
                    required=""
                  />
                </div>
              </div>
            </div>

            <div className="w-full flex items-center mt-4">
              <div className="flex">
                <input
                  id="terms_and_conditions"
                  name="terms_and_conditions"
                  aria-describedby="terms_and_conditions"
                  type="checkbox"
                  checked={formData.terms_and_conditions}
                  className="w-4 h-4 accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                  required={true}
                  onChange={handleChange}
                />
                <div className="ml-3 text-sm">
                  <label htmlFor="terms_and_conditions" className="font-light text-gray-500">
                    I accept the{' '}
                    <Link className="font-medium text-[#832EE6]" to="/terms-of-conditions">
                      Terms and Conditions
                    </Link>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex mt-10 mb-12 w-full">
              <button
                type="submit"
                className="bg-[#832EE6] hover:bg-purple-800 text-white px-[45px] mx-auto py-[15px] text-[18px] leading-6 font-bold inline-block rounded-[27px]"
              >
                Sign up
              </button>
            </div>
          </form>
          <div className='max-w-md mx-auto'>
            <p className='text-center font-bold text-base mb-8'>Or sign up with your social media account</p>
            <div className='flex gap-4 justify-between'>
              <FacebookLoginButton />
              <GoogleLoginButton />
            </div> 
            </div>
        </div>
      </div>
    </>
  );
}

export default RegisterForm;
import React, { useState } from 'react';
import axios from 'src/api/axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

const CloseAccountModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    reason: '',
    password: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      const response = await axios.delete('/api/my/close-account', { data: formData });
      setTimeout(() => {
        onClose();
        setIsSubmitting(false);
        NotificationManager.success(response.data.message || 'Account closed successfully');
        setTimeout(() => {
          dispatch({ type: 'LOGOUT' });
          window.location.href = '/';
        }, 5000);
      }, 1000); // Add delay to show animation
    } catch (error) {
      setIsSubmitting(false);
      NotificationManager.error(error.response?.data?.error || error || 'An error occurred while closing your account');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto mt-20">
        <h2 className="text-xl font-semibold mb-4">Close Account</h2>
        <p className="text-gray-600 mb-4">
          Are you sure you want to close your account? This action cannot be undone.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Why are you leaving? *</label>
              <textarea
                name="reason"
                required
                rows={4}
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-3 py-2"
                value={formData.reason}
                onChange={handleChange}
                placeholder="Please tell us why you're closing your account..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Confirm your password *</label>
              <input
                type="password"
                name="password"
                required
                className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-3 py-2"
                value={formData.password}
                onChange={handleChange}
                placeholder="Enter your password to confirm"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
              disabled={isSubmitting}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 relative ${
                isSubmitting ? 'cursor-not-allowed opacity-75' : ''
              }`}
            >
              {isSubmitting ? (
                <>
                  <span className="opacity-0">Close Account</span>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                  </div>
                </>
              ) : (
                'Close Account'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CloseAccountModal;
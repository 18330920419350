import React, { useCallback, useState } from 'react';
import axios from 'src/api/axios';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from 'src/redux/actions/modalActions';
import FacebookLoginButton from 'src/components/form/social-buttons/FacebookLoginButton';
import GoogleLoginButton from 'src/components/form/social-buttons/GoogleLoginButton';

function LoginForm(props) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/api/login', {
        email: formData.email,
        password: formData.password,
      });
      if (response.status === 200) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
        dispatch(hideModal());
        NotificationManager.success('Login successful');
      } else {
        NotificationManager.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      if (!error.response) {
        NotificationManager.error('No server reponse');
      } else if (error.response?.status === 400) {
        NotificationManager.error('Missing username or password');
      } else if (error.response?.status === 401) {
        NotificationManager.error('Unauthorized');
      } else {
        NotificationManager.error(error.message);
        if (error.response.data.message) {
          NotificationManager.error(error.response.data.message);
        }
        console.log(error);
      }
    }
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  return (
    <>
      <div className="w-full mx-auto bg-white md:mt-0 sm:max-w-md lg:max-w-[496px]">
        <div>
          <h2 className="text-[35px] leading-[45px] font-bold text-center">
            Log in
          </h2>
          <form className="" onSubmit={handleSubmit}>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] block w-full mt-[30px] p-2.5"
                placeholder="name@company.com"
                required=""
              />
            </div>
            <div>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Password"
                className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] block w-full mt-5 p-2.5"
                required=""
              />
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input 
                    id="remember" 
                    aria-describedby="remember" 
                    type="checkbox" 
                    className="w-4 h-4 accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" 
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="remember" className="text-sm">Remember me</label>
                </div>
              </div>
              <span className="text-sm font-bold cursor-pointer" onClick={() => dispatch(showModal('FORGOT_PASSWORD_MODAL'))}>Forgot password?</span>
            </div>
            <div className="flex mt-10 mb-12">
              <button type="submit" className="bg-[#832EE6] hover:bg-purple-800 text-white px-[45px] mx-auto py-[15px] text-[18px] leading-6 font-bold inline-block rounded-[27px]">
                Log in
              </button>
            </div>
          </form>
          <p className='text-center font-bold text-base mb-8'>Or sign in with your social media account</p>

          <div className='flex gap-4 justify-between'>
            <FacebookLoginButton />
            <GoogleLoginButton />
          </div>

          <p className="text-base mt-10 text-center">
            Need an account? <span onClick={() => dispatch(showModal('REGISTER_MODAL'))} className="font-bold text-[#832EE6] cursor-pointer">Sign up</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
export interface ShowModalAction {
  type: 'SHOW_MODAL';
  payload: {
    modalType: string;
    modalProps?: any;
  };
}

export interface HideModalAction {
  type: 'HIDE_MODAL';
}

export type ModalAction = ShowModalAction | HideModalAction;

export const showModal = (modalType: string, modalProps?: any): ShowModalAction => ({
  type: 'SHOW_MODAL',
  payload: {
    modalType,
    modalProps
  }
});

export const hideModal = (): HideModalAction => ({
  type: 'HIDE_MODAL'
});
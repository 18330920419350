import React, { useRef, useState, useEffect } from 'react';
import uploadImage from 'src/assets/svg/featuredimage.svg';
import deleteIcon from 'src/assets/svg/delete.svg';
import axios from 'src/api/axios';
import { NotificationManager } from 'react-notifications';

const MultipleImageUpload = ({ initialImages = [], onImagesChange }) => {
    const fileInputRef = useRef(null);
    const [galleryImages, setGalleryImages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(new Map());

    useEffect(() => {
        if (Array.isArray(initialImages) && initialImages.length > 0) {
            const formattedImages = initialImages.map(img => ({
                id: img.id,
                path: img.path,
                isNew: false,
                url: img.public_url || img.original_url,
                name: img.name
            }));
            setGalleryImages(formattedImages);
        }
    }, [initialImages]);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleDeleteImage = (indexToDelete) => {
        const updatedGallery = galleryImages.filter((_, index) => index !== indexToDelete);
        setGalleryImages(updatedGallery);
        if (onImagesChange) {
            onImagesChange(updatedGallery);
        }
        NotificationManager.info('Image deleted');
    };

    const handleFileSelect = async (event) => {
        const files = Array.from(event.target.files);
        setIsUploading(true);

        try {
            const uploadPromises = files.map(async (file, index) => {
                const maxFileSize = 32 * 1024 * 1024; // 32MB
                if (file.size > maxFileSize) {
                    NotificationManager.error('File size should not exceed 32MB');
                    return null;
                }

                const formData = new FormData();
                formData.append('image', file);

                setUploadProgress(prev => {
                    const newMap = new Map(prev);
                    newMap.set(galleryImages.length + index, 0);
                    return newMap;
                });

                const response = await axios.post('api/media/upload-image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prev => {
                            const newMap = new Map(prev);
                            newMap.set(galleryImages.length + index, progress);
                            return newMap;
                        });
                    }
                });

                return {
                    id: response.data.data.id,
                    path: response.data.data.path,
                    url: response.data.data.public_url || response.data.data.original_url,
                    name: response.data.data.name,
                    isNew: true
                };
            });

            const uploadedImages = await Promise.all(uploadPromises);
            const validImages = uploadedImages.filter(img => img !== null);
            
            const updatedGallery = [...galleryImages, ...validImages];
            setGalleryImages(updatedGallery);
            
            if (onImagesChange) {
                onImagesChange(updatedGallery);
            }

            NotificationManager.success('Images uploaded successfully');
        } catch (error) {
            console.error('Upload error:', error);
            NotificationManager.error(error.response?.data?.message || 'Error uploading images');
        } finally {
            setIsUploading(false);
            setUploadProgress(new Map());
        }
    };

    return (
        <div className="max-w-[400px] w-full">
            <div className="flex justify-between mb-4">
                <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="multiple_images">
                    Add gallery
                </p>
                <div
                    className='rounded-[5px] py-1.5 px-8 bg-[#832EE61C] text-[#832EE6] text-[13px] cursor-pointer'
                    onClick={handleUploadClick}
                >
                    Upload
                </div>
            </div>
            <div className="flex items-center justify-center w-full sp-scrollbar">
                <label htmlFor="multiple-dropzone-file" className="grid grid-cols-2 gap-[15px] w-full h-[234px] overflow-auto cursor-pointer bg-white">
                    {galleryImages.map((image, index) => (
                        <div key={image.id || index} className='border border-[#DEDEDE] border-dashed rounded-[3px] h-[105px] flex flex-col justify-center items-center relative group'>
                            <img src={image.url} alt={`Gallery image ${index + 1}`} className='w-full max-w-full max-h-[105px] object-contain' />
                            {isUploading && uploadProgress.has(index) && (
                                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                    <div className="w-3/4">
                                        <div className="relative pt-1">
                                            <div className="flex mb-2 items-center justify-between">
                                                <div>
                                                    <span className="text-xs font-semibold inline-block text-white">
                                                        {uploadProgress.get(index)}%
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white bg-opacity-20">
                                                <div 
                                                    style={{ width: `${uploadProgress.get(index)}%` }}
                                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-600 transition-all duration-300"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <button 
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDeleteImage(index);
                                }}
                                className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 md:opacity-70 hover:opacity-100"
                            >
                                <img src={deleteIcon} alt="delete" className="w-4 h-4 brightness-0 invert" />
                            </button>
                        </div>
                    ))}
                    
                    {Array.from({ length: Math.max(1, 4 - galleryImages.length) }).map((_, index) => (
                        <div 
                            key={`empty-${index}`} 
                            className='border border-[#DEDEDE] border-dashed rounded-[3px] h-[105px] flex flex-col justify-center items-center'
                            style={index === 0 ? {cursor: 'pointer'} : {}}
                        >
                            <img src={uploadImage} alt="uploadImage" />
                        </div>
                    ))}
                
                    <input
                        id="multiple-dropzone-file"
                        type="file"
                        className="hidden"
                        accept="image/png, image/jpeg"
                        onChange={handleFileSelect}
                        ref={fileInputRef}
                        multiple
                    />
                </label>
            </div>
        </div>
    );
};

export default MultipleImageUpload;

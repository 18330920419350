import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import noImage from 'src/assets/svg/no-image.svg';

const BlogCard = ({ post }) => {
  const url = `/blog/${post.slug}`;
  
  return (
    <article className="flex flex-col w-full bg-white border border-gray-200 rounded-lg shadow hover:shadow-lg transition-shadow duration-300">
      {/* Featured Image */}
      <Link to={url} className="block overflow-hidden rounded-t-lg aspect-[4/3]">
        <img 
          src={post.featured_image || noImage} 
          alt={post.title}
          loading="lazy"
          className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
        />
      </Link>

      {/* Content */}
      <div className="p-3 sm:p-4 flex flex-col flex-grow">
        {/* Meta info */}
        <div className="flex items-center justify-between mb-2 sm:mb-3 text-xs sm:text-sm text-gray-600">
          <time dateTime={post.created_at}>
            {post.created_at}
          </time>
          {post.category && (
            <Link 
              to={`/blog/category/${post.category.slug}`}
              className="text-[#832EE6] hover:text-[#6f26c4]"
            >
              {post.category.name}
            </Link>
          )}
        </div>

        {/* Title */}
        <Link to={url}>
          <h2 className="mb-2 text-lg sm:text-xl font-bold tracking-tight text-gray-900 hover:text-[#832EE6] line-clamp-2">
            {post.title}
          </h2>
        </Link>

        {/* Excerpt */}
        <p className="mb-3 sm:mb-4 text-xs sm:text-sm text-gray-700 line-clamp-2 sm:line-clamp-3">
          {post.excerpt || post.content?.substring(0, 150) + '...'}
        </p>

        {/* Author info */}
        {post.author && (
          <div className="mt-auto flex items-center">
            <img 
              className="w-6 h-6 sm:w-8 sm:h-8 rounded-full mr-2"
              loading="lazy"
              src={post.author.avatar || '/images/default-avatar.png'}
              alt={post.author.name}
            />
            <span className="text-xs sm:text-sm text-gray-600">
              {post.author.name}
            </span>
          </div>
        )}
      </div>
    </article>
  );
};

BlogCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    featured_image: PropTypes.string,
    excerpt: PropTypes.string,
    content: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string,
    }),
  }).isRequired,
};

export default BlogCard;
import React, { useState, useEffect } from 'react';
import axios from 'src/api/axios';
import { NotificationManager } from 'react-notifications';
import { useCookies } from 'react-cookie';

const UserPhoneModal = ({ isOpen, onClose, user }) => {
    const [cookies] = useCookies(['cookieConsent']);
    const [phone, setPhone] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null); // State to hold error messages

    useEffect(() => {
        const loadRecaptcha = () => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
                script.async = true;
                script.onload = () => {
                    // Wait for grecaptcha to be ready
                    window.grecaptcha.ready(resolve);
                };
                document.body.appendChild(script);
            });
        };

        if (isOpen && cookies.cookieConsent?.necessary) {
            loadRecaptcha()
                .then(() => {
                    fetchPhoneNumber();
                })
                .catch((error) => {
                    console.error('Error loading reCAPTCHA:', error);
                    setError('Failed to load reCAPTCHA');
                    setIsLoading(false);
                });
        }
    }, [isOpen, cookies.cookieConsent]);

    if (!isOpen) return null;

    const fetchPhoneNumber = async () => {
        try {
            setIsLoading(true);
            setError(null); // Reset error state before verification

            // Execute reCAPTCHA
            const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
                action: 'view_phone'
            });

            // Verify token and get phone number
            const response = await axios.post(`/api/user/${user.slug}/get-phone-number`, {
                recaptcha_token: token
            }, { disableNotification: true });

            if (response.status === 200) {
                setPhone(response.data.data);
            } else {
                setError(response.data.message); // Set error message
            }
        } catch (error) {
            console.error('Error verifying:', error);
            setError(error.response?.data?.message || 'An error occurred, please try again in a moment. If the problem persists, please contact us at support@sphynxplace.com'); // Set error message
        } finally {
            setIsLoading(false);
        }
    };

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center" onClick={handleBackdropClick}>
            <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto animate-slideDown relative">
                <button 
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                
                <h2 className="text-xl font-semibold mb-4">Contact Information</h2>
                
                {!cookies.cookieConsent?.necessary ? (
                    <div className="flex flex-col items-center mb-8 bg-yellow-50 rounded-lg p-6">
                        <div className="bg-yellow-100 rounded-full p-4 mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <p className="text-yellow-800 text-center font-medium">Please accept cookies to view contact information</p>
                    </div>
                ) : (
                    <>
                        {isLoading ? (
                            <div className="flex justify-center items-center py-8">
                                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#832EE6]"></div>
                            </div>
                        ) : (
                            <>
                                {error ? (
                                    <div className="flex flex-col items-center mb-8 bg-red-50 rounded-lg p-6">
                                        <div className="bg-red-100 rounded-full p-4 mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                        <p className="text-red-800 text-center font-medium">{error}</p>
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center mb-8 bg-gray-50 rounded-lg p-6">
                                        <div className="bg-[#832EE6] rounded-full p-4 mb-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                            </svg>
                                        </div>
                                        <p className="text-gray-500 text-sm uppercase tracking-wide mb-2">Phone Number</p>
                                        <a href={`tel:${phone?.phone_number}`} className="text-2xl font-bold text-[#832EE6] tracking-wider hover:underline">{phone?.phone_number}</a>
                                        {phone?.is_verified ? (
                                            <div className="mt-2 flex items-center text-green-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                </svg>
                                                <span className="text-sm font-medium">Verified phone number</span>
                                            </div>
                                        ) : (
                                            <div className="mt-2 flex items-center text-yellow-600">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                </svg>
                                                <span className="text-sm font-medium">Unverified phone number</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default UserPhoneModal;
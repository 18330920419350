import React, { useState, useEffect } from "react";
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import UserBox from 'src/components/UserBox';
import ArticleList from 'src/components/section/ArticleList';
import report from 'src/assets/svg/report.svg';

const handleReport = () => {
  console.log('Report');
}


const UserProfil = () => {

  return (
    <>
      <Header />
      <div className='bg-[#F7F7F7] py-[35px] min-h-screen'>
        <div className='container mx-auto px-4 flex gap-5 justify-between'>
          <div className="max-w-[332px] w-full">
            <div className='bg-white border border-[#EBEBEB] rounded-lg p-5'>
              <UserBox />
              <div className='text-center'>
                    <button onClick={handleReport} className='inline-flex'><img src={report} alt="report" className='h-[14px] my-auto' /><span className='text-[#B92222] text-base ml-2 my-auto'>Report advert</span></button>
                </div>
            </div>
          </div>
          <div className="w-full">
            <ArticleList />
            <ArticleList />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default UserProfil
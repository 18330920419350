import React from 'react';
import LoginForm from 'src/components/form/LoginForm';
import { hideModal } from 'src/redux/actions/modalActions';
import { useDispatch } from 'react-redux';

const LoginModal = () => {
  const dispatch = useDispatch();
  
  const onClose = e => {
    dispatch(hideModal());
  };

  const handleBackdropClick = e => {
    if (e.target === e.currentTarget) {
      onClose(e);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center animate-fadeIn"
      onClick={handleBackdropClick}
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-md animate-blowUp">
        <div className="flex justify-end items-center mb-4">
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <LoginForm onClose={onClose} inModal={true} />
      </div>
    </div>
  );
};

export default LoginModal;

import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import UserBox from 'src/components/UserBox';
import ArticleList from 'src/components/section/ArticleList';
import UserSidebar from 'src/components/UserSidebar';
import axios from 'src/api/axios';
import noAdverts from 'src/assets/svg/nofavourites.svg';
import Pagination from "src/components/common/Pagination";
import { useSearchParams } from "react-router-dom";

const MyAdverts = () => {
  const [articles, setArticles] = useState([]);
  const [stats, setStats] = useState({ total_published: '?', total_finished: '?' });
  const [loading, setLoading] = useState(true);
  const [meta, setMeta] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams({ status: 'published' });

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`/api/my/articles?${searchParams.toString()}`);
        setArticles(data.data);
        setMeta(data.meta);
        setStats({
          total_published: data.total_published,
          total_finished: data.total_finished
        });
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [searchParams]);

  const updateSearchParams = (updates) => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(updates).forEach(([key, value]) => {
      if (value === null) {
        newParams.delete(key);
      } else {
        newParams.set(key, value);
      }
    });
    setSearchParams(newParams);
  };

  const handleStatusChange = (e) => {
    updateSearchParams({
      status: e.target.value,
      page: null
    });
  };

  const handlePageChange = (page) => {
    updateSearchParams({ page });
  };

  return (
    <>
      <Header />
      <div className='bg-[#F7F7F7] py-[35px] min-h-screen'>
        <div className='container mx-auto px-2 flex flex-col md:flex-row gap-5 justify-between relative'>
          <div className="w-full md:w-[332px] md:min-w-[332px]">
            <UserSidebar />
          </div>
          
          <div className="w-full">
            <div className='flex gap-[15px] flex-col md:flex-row 2xl:gap-[32px] justify-between mb-5'>
              <p className='my-auto uppercase font-bold text-[24px] leading-7'> My Adverts</p>
              <div className="flex">
                <div className="flex input-wrapper-bold items-center mr-4">
                  <input 
                    name='status'
                    checked={searchParams.get('status') === 'published'}
                    id="status-published"
                    type="radio"
                    value="published"
                    onChange={handleStatusChange}
                    className="peer w-4 h-4 rounded accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="status-published" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Active ({stats.total_published})
                  </label>
                </div>
                <div className="flex input-wrapper-bold items-center mr-4">
                  <input 
                    name='status' 
                    checked={searchParams.get('status') === 'finished'}
                    id="status-finished" 
                    type="radio" 
                    value="finished"
                    onChange={handleStatusChange} 
                    className="peer w-4 h-4 rounded accent-purple-600 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="status-finished" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                    Inactive ({stats.total_finished})
                  </label>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="min-h-[200px] flex items-center justify-center">
                <div className="w-12 h-12 border-4 border-purple-600 rounded-full animate-spin border-t-transparent"></div>
              </div>
            ) : articles.length > 0 ? (
              <>
                {articles.map((article) => (
                  <ArticleList key={article.id} article={article} />
                ))}
                {meta?.total > 0 && meta?.last_page > 1 && <Pagination meta={meta} onPageChange={handlePageChange} />}
              </>
            ) : (
              <div className='flex flex-col'>
                <img src={noAdverts} alt="no adverts" className="mx-auto mt-5 md:mt-10 w-[200px] md:w-auto"/>
                <h2 className="uppercase font-bold text-[20px] md:text-[24px] leading-[28px] md:leading-[35px] my-[30px] md:my-[50px] text-center">
                  No {searchParams.get('status') === 'published' ? 'active' : 'inactive'} ads at the moment
                </h2>
                <Link 
                  to="/post-ad" 
                  className="bg-[#832EE6] hover:bg-purple-800 text-white px-[30px] md:px-[45px] mx-auto py-[12px] md:py-[16px] text-[16px] md:text-[18px] leading-5 md:leading-6 font-bold inline-block rounded-[27px]"
                >
                  + NEW ADVERT
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default MyAdverts

import React, { useState } from 'react';

const CopyLinkButton = ({ link }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    try {
      navigator.clipboard.writeText(link);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    } catch (error) {
      const textarea = document.createElement('textarea');
      textarea.value = link;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }
  };

  return (
    <div className="relative">
      <button onClick={copyToClipboard} className='rounded-[5px] py-1.5 px-8 bg-[#832EE61C] text-[#832EE6] text-[13px]'>
        Copy Link
      </button>
      {showTooltip && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap">
          Link copied!
        </div>
      )}
    </div>
  );
};

export default CopyLinkButton;
import { React, useState, useEffect } from 'react';
import axios from 'src/api/axios';
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { NavLink } from "react-router-dom";
import camera from 'src/assets/svg/add-photo.svg';
import verified from 'src/assets/svg/verified.svg';
import notVerified from 'src/assets/svg/check-false.svg';
import UserSidebar from 'src/components/UserSidebar';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import CountrySelect from 'src/components/CountrySelect';
import PhoneVerificationModal from 'src/components/modals/PhoneVerificationModal';
import CloseAccountModal from 'src/components/modals/CloseAccountModal';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
import facebook from 'src/assets/svg/footerfacebook.svg';

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);

  const [user, setUser] = useState(auth.user);
  const [formData, setFormData] = useState({
    email: user.email,
    account_type: user.account_type,
    first_name: user.first_name,
    last_name: user.last_name,
    profile_name: user.profile_name,
    phone_number: user.phone?.number,
    type: user.type,
  });

  useEffect(() => {
    axios.get('api/my/info').then(response => {
      const userData = response.data.data;
      setUser(userData);
      setFormData(prevData => ({
        ...prevData,
        ...userData,
        country: userData.country?.cca2,
        phone_number: userData.phone?.number,
      }));
    });
  }, []);

  const handleChange = event => {
    const { name, value, type, checked } = event.target;
    setFormData(prevFormData => ({ 
      ...prevFormData, 
      [name]: type === 'checkbox' ? checked : value 
    }));
  };

  const handleProfilePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      NotificationManager.error('File size should not exceed 5MB');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post('api/media/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        }
      });

      setUser(prevUser => ({ ...prevUser, avatar: response.data.data.public_url }));
      setFormData(prevFormData => ({ ...prevFormData, new_avatar: response.data.data.path }));
      NotificationManager.success('Profile photo updated successfully');
    } catch (error) {
      NotificationManager.error(error.response?.data?.message || 'Error uploading image');
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Validate password fields if they are filled
    if (formData.new_password || formData.confirm_new_password) {
      if (!formData.current_password) {
        NotificationManager.error('Current password is required to change password');
        return;
      }
      if (formData.new_password !== formData.confirm_new_password) {
        NotificationManager.error('New passwords do not match');
        return;
      }
    }

    try {
      const response = await axios.post('api/my/settings', formData);
      dispatch({ type: 'UPDATE_USER', payload: response.data.data });
      setUser(response.data.data);
      NotificationManager.success(response.data.message || 'Settings updated successfully');
    } catch (error) {
      if (!error.response) {
        NotificationManager.error('Network error - please check your connection');
      } else if (error.response?.status === 400) {
        NotificationManager.error(error.response.data.message || 'Invalid input data');
      } else if (error.response?.status === 401) {
        NotificationManager.error('Please login again to continue');
        dispatch({ type: 'LOGOUT' });
        navigate('/login');
      } else {
        NotificationManager.error(error.response?.data?.message || 'An error occurred');
      }
    }
  };

  return (
    <>
      <Header />
      <div className='bg-[#F7F7F7] py-[35px] min-h-screen'>
        <div className='container mx-auto px-2'>
          <div className='flex flex-col lg:flex-row gap-5 justify-between relative'>
            <div className='w-full md:w-[332px] md:min-w-[332px]'>
              <UserSidebar />
            </div>
            <div className="w-full">
              <div className='flex gap-[15px] 2xl:gap-[32px] justify-between mb-5'>
                <p className='my-auto uppercase font-bold text-xl md:text-[24px] leading-7'>My Account</p>
              </div>
              <form action="#" onSubmit={handleSubmit}>
                <div className="px-4 md:px-8 lg:px-14 py-[30px] bg-white border border-[#EBEBEB] rounded-lg flex flex-col md:flex-row gap-5 justify-between mb-5" style={{ boxShadow: '0px 3px 6px #8D8D8D36' }}>
                  <div className="w-full md:w-1/2">
                    <p className="font-bold text-[17px] leading-6 pb-[25px]">General information</p>
                    <div className="space-y-2 max-w-full md:max-w-[400px]">
                      <div className="relative">
                        <label htmlFor="countries" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">Account type</label>
                        <select
                          name="account_type"
                          id="account_type"
                          onChange={handleChange}
                          value={formData.account_type}
                          className="!pt-[30px] uppercase !pb-2 bg-white font-medium border border-gray-300 text-[#E68A2E] text-base leading-5 rounded-[4px] focus:ring-blue-500 focus:border-blue-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option value="individual">Individual</option>
                          <option value="breeder">Breeder</option>
                          <option value="company">Company</option>
                        </select>
                      </div>
                      <div className="relative">
                        <label htmlFor="first_name" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">First name</label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          value={formData.first_name}
                          onChange={handleChange}
                          className="!pt-[30px] !pb-2 bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          required
                        />
                      </div>
                      <div className="relative">
                        <label htmlFor="last_name" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">Last name</label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          value={formData.last_name}
                          onChange={handleChange}
                          className="!pt-[30px] !pb-2 bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                          required
                        />
                      </div>
                      {(formData.account_type === 'breeder' || formData.account_type === 'company') &&
                        <div className="relative">
                          <label htmlFor="profile_name" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">Profile name</label>
                          <input
                            type="text"
                            id="profile_name"
                            name="profile_name"
                            value={formData.profile_name}
                            onChange={handleChange}
                            className="!pt-[30px] !pb-2 bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            required
                          />
                        </div>
                      }
                    </div>
                  </div>
                  <div className="flex flex-col w-full md:w-1/2 -order-1 md:order-1">
                    <p className="font-bold text-[17px] leading-6 pb-[25px]">Profile photo</p>
                    <div className="flex flex-grow">
                      <button 
                        className="rounded-full bg-[#404040] h-max mx-auto max-w-[171px] 2xl:max-w-[200px] w-full aspect-square flex relative" 
                        type="button" 
                        onClick={() => document.getElementsByName('new_avatar')[0]?.click()}
                      >
                        <input 
                          type="file" 
                          name="new_avatar" 
                          onChange={handleProfilePhotoUpload} 
                          className="hidden" 
                          accept="image/*" 
                        />
                        {user.avatar ? (
                          <img src={user.avatar} alt="profile" className="w-full h-full object-cover rounded-full" />
                        ) : (
                          <span className='m-auto text-white font-bold text-[71px]'>{user.first_name?.[0]}{user.last_name?.[0]}</span>
                        )}
                        
                        {isUploading && (
                          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                            <div className="w-3/4">
                              <div className="relative pt-1">
                                <div className="flex mb-2 items-center justify-between">
                                  <div>
                                    <span className="text-xs font-semibold inline-block text-white">
                                      {uploadProgress}%
                                    </span>
                                  </div>
                                </div>
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white bg-opacity-20">
                                  <div 
                                    style={{ width: `${uploadProgress}%` }}
                                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-600 transition-all duration-300"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        <span className="border bg-white border-[#D6D6D6] w-[37px] h-[37px] rounded-full flex absolute bottom-0 2xl:bottom-[10px] right-[20px]">
                          {isUploading ? (
                            <svg className="animate-spin h-5 w-5 m-auto text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          ) : (
                            <img src={camera} alt="camera" width="14" height="14" className="!w-[14px] m-auto" />
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="px-4 md:px-8 lg:px-14 py-[30px] bg-white border border-[#EBEBEB] rounded-lg flex flex-col gap-5 mb-5" style={{ boxShadow: '0px 3px 6px #8D8D8D36' }}>
                  <div className="w-full">
                    <p className="font-bold text-[17px] leading-6 pb-[25px]">Contact details</p>
                    <div className="space-y-4">
                      <div className="flex flex-col md:flex-row gap-4">
                      <div className="w-full md:w-1/2 md:max-w-[400px]">
                          <div className="relative">  
                            <label htmlFor="country" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">Country</label>
                            <CountrySelect 
                              value={formData.country}
                              onChange={handleChange}
                              name="country"
                              className="!pt-[30px] !pb-2 bg-transparent font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            />
                        </div>
                      </div>

                        <div className="w-full md:w-1/2 md:max-w-[400px]">
                          <div className="relative">  
                            <label htmlFor="city" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">City</label>
                            <input
                              type="text"
                              id="city"
                              name="city"
                              value={formData.city}
                              onChange={(e) => {
                                e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                                handleChange(e);
                              }}
                              className="!pt-[30px] !pb-2 bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                        <div className="w-full md:w-1/2 md:max-w-[400px]">
                          <div className="relative w-full">
                            <label htmlFor="phone_number" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">Phone number</label>
                            <PhoneInput
                              country="US"
                              value={formData.phone_number}
                              name="phone_number"
                              onChange={(value) => {
                                handleChange({ target: { name: 'phone_number', value: value }});
                              }}
                              className="!pt-[30px] !pb-2 bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500  w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 flex items-end">
                          <div className="flex items-center w-full">
                            <input 
                              name="show_phone" 
                              id="show_phone" 
                              type="checkbox" 
                              checked={formData.show_phone}
                              onChange={handleChange}
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor="show_phone" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Show phone number on my profile</label>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col md:flex-row gap-2 md:gap-4">
                        <div className="w-full md:w-1/2 md:max-w-[400px]">
                          <div className="relative">  
                            <label htmlFor="email" className="absolute top-0 pt-2.5 px-[15px] block text-xs text-[#656565] dark:text-white">Email</label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              className="!pt-[30px] !pb-2 bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                            />
                          </div>
                        </div>

                        <div className="w-full md:w-1/2 flex items-end">
                          <div className="flex items-center w-full">
                            <input 
                              name="show_email" 
                              id="show_email" 
                              type="checkbox" 
                              checked={formData.show_email}
                              onChange={handleChange}
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor="show_email" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Show email on my profile</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="px-4 md:px-8 lg:px-14 py-[30px] bg-white border border-[#EBEBEB] rounded-lg mb-[30px]" style={{ boxShadow: '0px 3px 6px #8D8D8D36' }}>
                  <p className="font-bold text-[17px] leading-6 pb-[25px]">Verification status</p>
                  <div className="space-y-4">

                    <div className='relative flex flex-col md:flex-row text-center md:h-[60px] bg-white border border-gray-300 rounded-[4px] block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                      <span className='whitespace-nowrap text-base font-bold leading-4 flex items-center'>
                        <img src={verified} alt="verified" width="17" height="17" className="!w-[17px] mr-[17px]" />
                        <span className="hidden sm:inline">Email address verified</span>
                        <span className="sm:hidden">Email verified</span>
                      </span>
                      <span className="text-left md:text-center font-medium text-base leading-5 pt-[5px] block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                        {formData.email}
                      </span>
                    </div>
                    <div className='relative flex flex-col md:flex-row text-center md:h-[60px] bg-white border border-gray-300 rounded-[4px] block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                      <span className='whitespace-nowrap text-base font-bold leading-4 flex items-center'>
                        <img 
                          src={user.phone?.is_verified ? verified : notVerified} 
                          alt={user.phone?.is_verified ? "verified" : "not verified"} 
                          width="17" 
                          height="17" 
                          className="!w-[17px] mr-[17px]" 
                        />
                        <span className="hidden sm:inline">
                          {user.phone?.is_verified ? 'Phone number verified' : 'Phone number not verified'}
                        </span>
                        <span className="sm:hidden">
                          {user.phone?.is_verified ? 'Phone verified' : 'Phone not verified'}
                        </span>
                      </span>
                      <span className="text-left md:text-center font-medium text-base leading-5 pt-[5px] block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                        {user.phone?.number ?? 'Please add a phone number'}
                      </span>
                      {!user.phone?.is_verified && user.phone?.number && (
                        <>
                          <button 
                            type="button"
                            className='text-base leading-4 text-[#832EE6] absolute right-4 top-1/2 -translate-y-1/2 flex'
                            onClick={() => setShowVerificationModal(true)}
                          >
                            Verify
                          </button>
                          <PhoneVerificationModal 
                            isOpen={showVerificationModal}
                            onClose={() => setShowVerificationModal(false)}
                            phoneNumber={formData.phone_number}
                            onVerificationSuccess={() => {
                              setUser(prev => ({
                                ...prev,
                                phone: { ...prev.phone, is_verified: true }
                              }));
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className='relative flex flex-col md:flex-row text-center md:h-[60px] bg-white border border-gray-300 rounded-[4px] block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                      <span className='whitespace-nowrap text-base font-bold leading-4 flex items-center'>
                        <img src={user.linked_accounts?.facebook ? verified : notVerified} alt={user.linked_accounts?.facebook ? "verified" : "not verified"} width="17" height="17" className="!w-[17px] mr-[17px]" />
                        <span className="hidden sm:inline">
                          {user.linked_accounts?.facebook ? 'Facebook verified' : 'Facebook not verified'}
                        </span>
                        <span className="sm:hidden">
                          {user.linked_accounts?.facebook ? 'FB verified' : 'FB not verified'}
                        </span>
                      </span>
                      {!user.linked_accounts?.facebook && (
                        <>
                          {process.env.REACT_APP_FB_APP_ID ? (
                            <LoginSocialFacebook 
                              className='text-base leading-4 text-[#832EE6] absolute right-4 top-1/2 -translate-y-1/2 flex cursor-pointer'
                              isOnlyGetToken
                              appId={process.env.REACT_APP_FB_APP_ID}
                              onResolve={async ({ provider, data }) => {
                                try {
                                  const response = await axios.post('/api/my/link/facebook', {
                                    provider_id: data.accessToken
                                  });
                                  if (response.status === 200) {
                                    NotificationManager.success('Facebook linked successfully');
                                    setUser(response.data.data);
                                  }
                                } catch (error) {
                                  console.error(error);
                                  NotificationManager.error('Facebook linking failed');
                                }
                              }}
                              onReject={(error) => {
                                console.error(error);
                                NotificationManager.error('Facebook linking failed');
                              }}
                              fields="name,email,picture"
                              scope="public_profile,email"
                            >
                              Link
                            </LoginSocialFacebook>
                          ) : (
                            <span className='text-base leading-4 text-gray-500 absolute right-4 top-1/2 -translate-y-1/2'>
                              Currently unavailable
                            </span>
                          )}
                        </>
                      )}
                      <span className="hidden md:text-center font-medium text-base leading-5 pt-[5px] block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                        {user.linked_accounts?.facebook ? `Linked ${user.linked_accounts.facebook.linked_at}` : 'Log in with your Facebook account'}
                      </span>
                    </div>

                    <div className='relative flex flex-col md:flex-row text-center md:h-[60px] bg-white border border-gray-300 rounded-[4px] block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:text-white'>
                      <span className='whitespace-nowrap text-base font-bold leading-4 flex items-center'>
                        <img src={user.linked_accounts?.google ? verified : notVerified} alt={user.linked_accounts?.google ? "verified" : "not verified"} width="17" height="17" className="!w-[17px] mr-[17px]" />
                        <span className="hidden sm:inline">
                          {user.linked_accounts?.google ? 'Google verified' : 'Google not verified'}
                        </span>
                        <span className="sm:hidden">
                          {user.linked_accounts?.google ? 'Google verified' : 'Google not verified'}
                        </span>
                      </span>
                      {!user.linked_accounts?.google && (
                        <>
                          {process.env.REACT_APP_GG_APP_ID ? (
                            <LoginSocialGoogle
                              className='text-base leading-4 text-[#832EE6] absolute right-4 top-1/2 -translate-y-1/2 flex cursor-pointer'
                              isOnlyGetToken
                              client_id={process.env.REACT_APP_GG_APP_ID}
                              onResolve={async ({ provider, data }) => {
                                try {
                                  const response = await axios.post('/api/my/link/google', {
                                    provider_id: data.access_token
                                  });
                                  if (response.status === 200) {
                                    NotificationManager.success('Google linked successfully');
                                    setUser(response.data.data);
                                  }
                                } catch (error) {
                                  console.error(error);
                                  NotificationManager.error('Google linking failed');
                                }
                              }}
                              onReject={(error) => {
                                console.error(error);
                                NotificationManager.error('Google linking failed');
                              }}
                              scope="email profile"
                            >
                              Link
                            </LoginSocialGoogle>
                          ) : (
                            <span className='text-base leading-4 text-gray-500 absolute right-4 top-1/2 -translate-y-1/2'>
                              Currently unavailable
                            </span>
                          )}
                        </>
                      )}
                      <span className="hidden md:text-center font-medium text-base leading-5 pt-[5px] block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                        {user.linked_accounts?.google ? `Linked ${user.linked_accounts.google.linked_at}` : 'Log in with your Google account'}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="px-4 md:px-8 lg:px-14 py-[30px] bg-white border border-[#EBEBEB] rounded-lg mb-[30px]" style={{ boxShadow: '0px 3px 6px #8D8D8D36' }}>
                  <p className="font-bold text-[17px] leading-6 pb-[25px]">Change password</p>
                  <div className="space-y-[18px] max-w-[400px]">
                    <input
                      type="password"
                      name="current_password"
                      onChange={handleChange}
                      placeholder="Your current password"
                      className="h-[60px] bg-white font-medium border border-gray-300 text-[#0D0D0D] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    />

                    <input
                      type="password"
                      name="new_password"
                      onChange={handleChange}
                      placeholder="New password"
                      className="h-[60px] bg-white font-medium border border-gray-300 text-[#0D0D0D] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    />

                    <input
                      type="password"
                      name="confirm_new_password"
                      onChange={handleChange}
                      placeholder="Confirm new password"
                      className="h-[60px] bg-white font-medium border border-gray-300 text-[#0D0D0D] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                    />
                  </div>
                </div>
                <div className='flex flex-row justify-between gap-4'>
                  <button 
                    type="submit" 
                    className="py-1.5 sm:py-3 px-3 sm:px-8 text-[14px] sm:text-[18px] font-bold text-center text-white rounded-[27px] bg-[#832EE6] hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full sm:w-auto"
                  >
                    Confirm changes
                  </button>
                  <button 
                    type="button" 
                    onClick={() => setShowCloseAccountModal(true)}
                    className="py-1.5 sm:py-3 px-3 sm:px-8 text-[14px] sm:text-[18px] border border-[#CBCBCB] text-center text-[#000] rounded-[27px] bg-transparent hover:bg-white focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 w-full sm:w-auto"
                  >
                    Close my account
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <CloseAccountModal 
        isOpen={showCloseAccountModal}
        onClose={() => setShowCloseAccountModal(false)}
      />
    </>
  );
}

export default Settings;
import React from 'react';
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';

const PrivacyPolicy = () => {
  return (
    <>
    <Header />
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl md:text-4xl font-bold mb-8">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
        <p className="mb-4">
          Welcome to sphynxplace.com. Your privacy is important to us. This Privacy Policy explains how we collect, use, 
          and protect your personal information when you use the Platform. By using sphynxplace.com, you agree to this Privacy Policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">2. Data We Collect</h2>
        <h3 className="text-xl font-semibold mb-2">During Registration:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Name, email, and password.</li>
        </ul>
        
        <h3 className="text-xl font-semibold mb-2">Ad Posting:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Pet details (gender, age, health, temperament, etc.), photos, and optional pricing.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Contact Details (Optional):</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Users can add and verify their phone number in the My Account section. Phone numbers will only be displayed in ads if the user chooses to make them public.</li>
        </ul>
        
        <h3 className="text-xl font-semibold mb-2">Automatically Collected Data:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>IP address, browser type, and device information.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">3. Legal Basis for Processing Personal Data</h2>
        <p className="mb-2">Under UK GDPR, we process your data based on the following legal grounds:</p>
        <h3 className="text-xl font-semibold mb-2">Consent:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>For non-essential cookies, newsletters, and optional user preferences.</li>
        </ul>
        <h3 className="text-xl font-semibold mb-2">Contractual Necessity:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>To enable account registration, ad posting, and platform functionality.</li>
        </ul>
        <h3 className="text-xl font-semibold mb-2">Legitimate Interests:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>For site security, fraud prevention, and analytics to improve user experience.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">4. How We Use Your Information</h2>
        <ul className="list-disc pl-6">
          <li>To enable account creation, ad posting, and profile customization.</li>
          <li>To display ads publicly, including pet details and photos.</li>
          <li>To verify user authenticity (e.g., phone number verification).</li>
          <li>To facilitate user interactions when contact details are made public.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">5. Data Sharing</h2>
        <ul className="list-disc pl-6">
          <li>sphynxplace.com does not share your personal information with third parties.</li>
          <li>Ad details (e.g., pet information, photos) are publicly visible, but personal contact information 
              (e.g., phone number, email) is displayed only if the user opts to do so.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">6. International Data Usage</h2>
        <p className="mb-4">
          Users may access the Platform and post ads from any country. Personal data may be processed and stored 
          in countries outside your own. sphynxplace.com ensures compliance with applicable data protection laws, 
          including UK GDPR.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">7. Cookies</h2>
        <p className="mb-2">We use cookies to:</p>
        <ul className="list-disc pl-6">
          <li>Save user preferences and improve website functionality.</li>
          <li>Track anonymous usage data via tools like Google Analytics.</li>
        </ul>
        <p className="mb-4">Users can manage cookie preferences through the cookie banner.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">8. User Rights</h2>
        <p className="mb-2">Users have the right to:</p>
        <ul className="list-disc pl-6">
          <li>Access and correct their personal data.</li>
          <li>Delete their account and request data removal.</li>
          <li>Control the visibility of their phone number and email in ads.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">9. Governing Law</h2>
        <p className="mb-4">
          This Privacy Policy is governed by the laws of England and Wales, and all disputes shall be subject to the jurisdiction of the courts of England and Wales.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">10. Contact Us</h2>
        <p className="mb-4">For questions or concerns, contact us at:</p>
        <ul className="list-none">
          <li>Email: <a href="mailto:support@sphynxplace.com">support@sphynxplace.com</a></li>
        </ul>
      </section>
    </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
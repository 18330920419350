import React from 'react';
import PropTypes from 'prop-types';

const adTypeConfig = {
    'wanted': {
        color: 'from-blue-600 to-blue-700',
        text: 'WANTED',
        icon: '🔍'
    },
    'for-sale': {
        color: 'from-green-600 to-green-700',
        text: 'FOR SALE',
        icon: '💰'
    },
    'for-adoption': {
        color: 'from-purple-600 to-purple-700',
        text: 'FOR ADOPTION',
        icon: '🏠'
    },
    'for-stud': {
        color: 'from-orange-600 to-orange-700',
        text: 'FOR STUD',
        icon: '🐱'
    }
};

const AdTypeBadge = ({ adType }) => {
    if (!adTypeConfig[adType]) return null;

    const { color, text, icon } = adTypeConfig[adType];

    return (
        <div className={`px-2 py-1 rounded text-white font-bold text-xs bg-gradient-to-r ${color}`}>
            <span className="mr-1">{icon}</span>
            {text}
        </div>
    );
};

AdTypeBadge.propTypes = {
    adType: PropTypes.oneOf(['wanted', 'for-sale', 'for-adoption', 'for-stud']).isRequired
};

export default AdTypeBadge; 
import React, {useEffect} from 'react'
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import {useDispatch} from 'react-redux';

const Logout = () => {
  const dispatch = useDispatch();
  dispatch({ type: 'LOGOUT' });
  useEffect(() => {
    setTimeout(() => {
      // 👇 Redirects to about page, note the `replace: true`
      window.location.href = '/';
      // navigate(-1);
    }, 1000);
  }, []);
  
  return (
    <>
      <Header />
      <div className="flex items-center justify-center min-h-[50vh]">
        <div className="text-2xl text-gray-600 font-light">
          Redirecting<span className="animate-pulse">...</span>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Logout
import React, { useState, useEffect } from "react";
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import UserBox from 'src/components/UserBox';
import ArticleList from 'src/components/section/ArticleList';
import axios from 'src/api/axios';
import { useParams, Link } from 'react-router-dom';
import ReportModal from 'src/components/modals/ReportModal';
import noFavorites from 'src/assets/svg/nofavourites.svg';

const Profil = () => {
  const { slug } = useParams();

  const [user, setUser] = useState(null);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        setLoading(true);
        const [userResponse, articlesResponse] = await Promise.all([
          axios.get(`/api/user/${slug}`),
          axios.get(`/api/user/${slug}/articles`)
        ]);
        setUser(userResponse.data.data);
        setArticles(articlesResponse.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [slug]);

  return (
    <>
      <Header />
      <div className='bg-[#F7F7F7] py-5 md:py-[35px] min-h-screen'>
        <div className='container mx-auto px-2 flex flex-col md:flex-row gap-4 md:gap-5 md:justify-between'>
          <div className="w-full md:max-w-[332px]">
            <div className='bg-white border border-[#EBEBEB] rounded-lg p-4 md:p-5'>
              <UserBox user={user} />
              <div className='text-center mt-3 md:mt-0'>
                <ReportModal 
                  type="user"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            {loading ? (
              <div className="flex items-center justify-center py-6 md:py-10">
                <div className="w-8 h-8 md:w-12 md:h-12 border-3 md:border-4 border-purple-600 rounded-full animate-spin border-t-transparent"></div>
              </div>
            ) : articles.length > 0 ? (
              articles.map((article, index) => (
                <ArticleList key={index} article={article} />
              ))
            ) : (
              <div className='flex flex-col'>
                <img src={noFavorites} alt="nofavorites" className="mx-auto mt-5 md:mt-10 w-[200px] md:w-auto"/>
                <h2 className="uppercase font-bold text-[20px] md:text-[24px] leading-[28px] md:leading-[35px] my-[30px] md:my-[50px] text-center">{user?.first_name} hasn't posted any articles yet</h2>
                <Link to="/search" className="bg-[#832EE6] hover:bg-purple-800 text-white px-[30px] md:px-[45px] mx-auto py-[12px] md:py-[16px] text-[16px] md:text-[18px] leading-5 md:leading-6 font-bold inline-block rounded-[27px]">Find a Sphynx</Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Profil
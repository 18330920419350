// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications/lib/notifications.css';
import 'src/styles/main.scss'; // Uvoz SCSS stila
import { NotificationContainer } from 'react-notifications';
import ModalManager from 'src/components/ModalManager';
import { Link, Route, Routes } from "react-router-dom";
import Home from "src/pages/Home";
import Contact from "src/pages/Contact";
import Ad from "src/pages/Ad";
import NotFound from "src/pages/NotFound";
import Login from 'src/pages/Login';
import Register from 'src/pages/Register';
import Search from 'src/pages/Search';
import Logout from 'src/pages/Logout';
import Settings from 'src/pages/my/Settings';
import Profil from 'src/pages/Profil';
import PostAd from 'src/pages/PostAd';
import EditAd from 'src/pages/my/EditAd';
import ScrollToTop from "src/components/ScrollToTop";
import UserProfil from 'src/pages/UserProfile';
import Favorites from 'src/pages/Favorites';
import MyAdverts from 'src/pages/my/MyAdverts';
import BlogList from 'src/pages/blog/Blog';
import BlogPost from 'src/pages/blog/BlogPost';

import { CookiesProvider } from 'react-cookie';
import CookieConsent from 'src/components/CookieConsent';
import PrivacyPolicy from 'src/pages/PrivacyPolicy';
import TermsAndConditions from 'src/pages/TermsAndConditions';


function App() {
  return (
    <CookiesProvider>
      <ScrollToTop />
      <NotificationContainer />
      <ModalManager />
      <CookieConsent />
      
      <Routes>
        <Route path="/logout" element={<Logout />}/>
        <Route path="/" element={<Home />} />
      
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/post-ad" element={<PostAd />} />

        <Route path="/search" element={<Search />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/ad">
          <Route path=":id/:slug" element={<Ad />}></Route>
        </Route>
        <Route path="/profil">
          <Route path=":slug" element={<Profil />}></Route>
        </Route>
        
        <Route path="/my">
          <Route path="settings" element={<Settings />}></Route>
          <Route path="ads" element={<MyAdverts />} />
          <Route path="favorites" element={<Favorites />} />
          <Route path="ad/:id/edit" element={<EditAd />} />
        </Route>

        <Route path="/blog">
          <Route path="" element={<BlogList />} />
          <Route path=":slug" element={<BlogPost />} />
        </Route>

        <Route path="user-profil" element={<UserProfil />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </CookiesProvider>
  );
}

export default App;

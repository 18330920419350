import React, { useState } from 'react';
import CopyLinkButton from 'src/components/CopyLinkButton';
import UserPhoneModal from 'src/components/modals/UserPhoneModal';

import pin from 'src/assets/svg/location.svg';
import time from 'src/assets/svg/time.svg';
import mobile from 'src/assets/svg/mobile.svg';
import email from 'src/assets/svg/email.svg';
import { useNavigate } from 'react-router-dom';
import ShareButton from 'src/components/ShareButton';

const UserBox = ({ user }) => {
    const navigate = useNavigate();
    const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);


    return (
        <>
                {user && (
                    <>
                        <div className='flex mb-6 cursor-pointer' onClick={() => navigate(`/profil/${user.slug}`)}>
                            {user.avatar ? (
                                <img src={user.avatar} alt="profile" className="rounded-full bg-[#404040] w-[54px] h-[54px] flex mr-4" />
                            ) : (
                                <p className='rounded-full bg-[#404040] w-[54px] h-[54px] flex mr-4'><span className='m-auto text-white font-bold text-[23px]'>{user.first_name?.charAt(0) + user.last_name?.charAt(0)}</span></p>
                            )}
                            <div>
                                <p className='py-0.5 px-3 text-[#E68A2E] bg-[#E68A2E30] rounded-[3px] inline-block text-center text-[10px] uppercase'>{user.account_type}</p>
                                <h3 className='text-[24px] leading-[35px]'>{user.profile_name}</h3>
                            </div>
                        </div>
                        <div className='border-b-[1px] border-b-[#DEDEDE] pb-2'>
                            {user.country && (
                                <p className='flex mb-3'>
                                    <img src={pin} alt="location" className='h-[15px] my-auto' />
                                    <span className='my-auto ml-1.5 text-base text-[#656565]'>{user.country.name}{user.city ? `, ${user.city}` : ''}</span>
                                </p>
                            )}
                            <p className='flex mb-3'><img src={time} alt="time" className='h-[15px] my-auto' /><span className='my-auto ml-1.5 text-base text-[#656565]'>{user.last_activity}</span></p>
                            <p className='text-base mb-3'><span className='mr-1.5'>Member since:</span><span className='text-[#656565]'>{user.member_since}</span></p>
                        </div>
                        <div className='border-b-[1px] border-b-[#DEDEDE] mt-5 pb-5'>
                            <h3 className='font-bold mb-3 text-base'>Contact seller:</h3>
                            <div className='flex gap-2'>
                                <button onClick={() => setIsPhoneModalOpen(true)} className='rounded-[10px] bg-[#832EE6] px-5 py-3 flex w-full justify-center items-center'>
                                    <img src={mobile} alt="mobile" className='mr-2' />
                                    <span className='text-white text-base'>Mobile</span>
                                </button>
                                <button onClick={() => setIsEmailModalOpen(true)} className='rounded-[10px] bg-[#832EE6] px-5 py-3 flex w-full justify-center items-center'>
                                    <img src={email} alt="email" className='mr-2' />
                                    <span className='text-white text-base'>E-mail</span>
                                </button>
                            </div>
                        </div>
                    </>
                )}
                <div className='mt-5 pb-6'>
                    <h3 className='font-bold mb-3 text-base'>Share on:</h3>
                    <div className='flex gap-2 justify-between'>
                        <ShareButton platform="facebook" />
                        <ShareButton platform="instagram" />
                        <ShareButton platform="pinterest" />
                        <CopyLinkButton link={window.location} />
                    </div>
                </div>

            <UserPhoneModal 
                isOpen={isPhoneModalOpen}
                onClose={() => setIsPhoneModalOpen(false)}
                user={user}
            />
        </>
    )
}

export default UserBox
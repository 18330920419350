import React, { useState } from 'react';
import axios from 'src/api/axios';
import report from 'src/assets/svg/report.svg';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';

const getReportText = (type) => {
  switch (type) {
    case 'user':
      return 'Report user';
    case 'post':
      return 'Report post';
    case 'ad':
      return 'Report ad';
    default:
      return 'Report';
  }
};

const ReportModal = ({ type }) => {
  const auth = useSelector((state) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    full_name: auth?.user?.first_name && auth?.user?.last_name ? auth.user.first_name + ' ' + auth.user.last_name : '',
    email: auth?.user?.email,
    phone: auth?.user?.phone?.number || '',
    content: ''
  });

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post('/api/report', {
        ...formData,
        type,
        url: window.location.href,
      });
      setTimeout(() => {
        handleClose();
        setIsSubmitting(false);
        NotificationManager.success('Report successfully submitted');
      }, 1000); // Add delay to show animation
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      NotificationManager.error('An error occurred while submitting the report');
    }
  };

  return (
    <div className="[&_*]:text-left">
      <button onClick={handleOpen} className='inline-flex items-center'>
        <img src={report} alt="report" className='h-3 md:h-[14px]' />
        <span className='text-[#B92222] text-sm md:text-base ml-2'>{getReportText(type)}</span>
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto mt-20">
            <h2 className="text-xl font-semibold mb-4">Report {type}</h2>
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Full Name *</label>
                  <input
                    type="text"
                    name="full_name"
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-3 py-2"
                    value={formData.full_name}
                    onChange={handleChange}
                  />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email *</label>
                  <input
                    type="email"
                    name="email"
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-3 py-2"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Phone (optional)</label>
                  <input
                    type="tel"
                    name="phone"
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-3 py-2"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Content *</label>
                  <textarea
                    name="content"
                    required
                    rows={4}
                    className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-primary focus:ring-primary px-3 py-2"
                    value={formData.content}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 relative ${
                    isSubmitting ? 'cursor-not-allowed opacity-75' : ''
                  }`}
                >
                  {isSubmitting ? (
                    <>
                      <span className="opacity-0">Submit Report</span>
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                      </div>
                    </>
                  ) : (
                    'Submit Report'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportModal;
import { configureStore } from '@reduxjs/toolkit';
// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import modalReducer from './reducers/modalReducer';
import authReducer from './reducers/authReducer';
// import axios from '../api/axios';

// const preloadedState = {
//   auth: {
//     "isLoggedIn": true,
//     "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZjJkNDBiODMyNzA3MWIzZWY4YTAwZmRmM2RiOWQ5MDJkYzM2ZDlhMTE1Mjc4ZTI4YmRlZWMzNTY3NmNmMDE1M2NmNTc5MTUwNmI5NjA2NDAiLCJpYXQiOjE2ODI1MDY0NDUuMDY0Njg2LCJuYmYiOjE2ODI1MDY0NDUuMDY0Njg4LCJleHAiOjE3MTQxMjg4NDUuMDU4MjU0LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.Y0Z8ehloLAgjOQ-p0p5Bq-hhWKyMAk_Y6mRipwPBSDnXG-04658FewJIjsaOOtWECIk3qq--FskZ1dr3Y1Ggkq0XbOrwuypEiG4T0MwE5CPp1uPSXekBEH5vy8AWH3_Mgna15dBh__DhY6kjWb6IRMXpyO4c3coW38WrzK_8leDjaY7O015FdzjWscWUDo-J_B4WN627JYDBkEnRxxQSGBzNf9Sjg_Tp7KpDrNHKmWF0_TdHcIyanjMKL4VOmhqgjAwxef0rFuj_Ii6qE8i91L3F6z_WJJRmKfofqCqw3klIApqHsj_PmWWrGAhvIfqWcfmpdC7As2RsfLxcc6DGU1M5kG_oIEfBleYdygoF0KoVdzCtnj-pU5Q0GjwtmtZa4ThvHAppatKAst_j4Bv9GB0MfBavLWLFwVZ29bPNqzYAoGtC2fsg05zyp3W-nAd5qARC1AJIRCE2xavhIvMEKIoLvC4JQC0lFfjbeAxEYGwLfMAaB_KQ6F92C1TB-Ci8bo4aeA4AuF0zsi11sV6qInwMOurkGuVH0vF5bp5x-lwL3dkgBO4cN-Yr9MhAKbIunPbFbKApzcXKgQQG68LhlCjDXNSdiiCNlhOfdzHWOMnPV_pGD_jQbe9oZ-axeAUsv6jqn6rhDu_nmYfnsb2usVM-yz2LXTjXJH6gD7lAoe8",
//     "user": {
//         "id": 1,
//         "first_name": "We1b",
//         "last_name": "Master",
//         "email": "webmaster@laracode.net",
//         "email_verified_at": null,
//         "address": null,
//         "zip_code": null,
//         "city": null,
//         "country_id": null,
//         "created_at": "2023-04-25T15:30:52.000000Z",
//         "updated_at": "2023-04-25T15:30:52.000000Z",
//         "deleted_at": null
//     },
//     "error": null
// },
//   visibilityFilter: 'SHOW_COMPLETED',
// }

export const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
  },
  // preloadedState,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([]),
});
import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'src/redux/actions/modalActions';
import { NotificationManager } from 'react-notifications';
import axios from 'src/api/axios';
import { LoginSocialFacebook } from 'reactjs-social-login';
import facebook from 'src/assets/svg/footerfacebook.svg';

function FacebookLoginButton() {
  const dispatch = useDispatch();
  if (!process.env.REACT_APP_FB_APP_ID) {
    return (
      <div 
        className="bg-[#3B5997] border rounded py-3 px-6 w-full text-white flex cursor-not-allowed opacity-50 relative group"
        style={{width: '100%'}}
      >
        <img src={facebook} alt="facebook" className="w-6" />
        <span className="ml-2 font-bold text-base">Facebook</span>
        <div className="absolute invisible group-hover:visible bg-gray-800 text-white text-sm rounded px-2 py-1 -top-8 left-1/2 transform -translate-x-1/2 whitespace-nowrap">
          Facebook login is not configured
        </div>
      </div>
    );
  }

  return (
    <LoginSocialFacebook 
      className="bg-[#3B5997] border rounded py-3 px-6 w-full text-white flex cursor-pointer"
      isOnlyGetToken
      appId={process.env.REACT_APP_FB_APP_ID}
      onResolve={async ({ provider, data }) => {
        try {
          const response = await axios.post('/api/auth/callback/facebook', {
            provider_id: data.accessToken
          });
          if (response.status === 200) {
            dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
            dispatch(hideModal());
            NotificationManager.success('Login successful');
          }
        } catch (error) {
          console.error(error);
          NotificationManager.error('Facebook login failed');
        }
      }}
      onReject={(error) => {
        console.error(error);
        NotificationManager.error('Facebook login failed');
      }}
      fields="name,email,picture"
      scope="public_profile,email"
    >
      <img src={facebook} alt="facebook" className="w-6" />
      <span className="ml-2 font-bold text-base my-auto">Facebook</span>
    </LoginSocialFacebook>
  );
}

export default FacebookLoginButton;
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from 'src/redux/actions/modalActions';
import axios from 'src/api/axios';

const ForgotPasswordForm = ({ onClose, inModal }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call your API endpoint for password reset
      // await api.sendPasswordResetEmail(email);
      const response = await axios.post('api/forgot-password', { email });
      NotificationManager.success(response.data.message);
      dispatch(hideModal());
    } catch (error) {
      console.error('Error:', error);
      NotificationManager.error(error.response.data.error || error.message);
    }
  };

  return (
    <>
      <div className="w-full mx-auto bg-white md:mt-0 sm:max-w-md lg:max-w-[496px]">
        <div>
          <h2 className="text-[35px] leading-[45px] font-bold text-center">
            Reset Password
          </h2>
          <form className="" onSubmit={handleSubmit}>
            <div>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 sm:text-sm rounded focus:ring-[#832EE6] focus:border-[#832EE6] block w-full mt-[30px] p-2.5"
                placeholder="Enter your email"
                required
              />
            </div>
            
            <div className="flex mt-10 mb-12">
              <button 
                type="submit"
                className="bg-[#832EE6] hover:bg-purple-800 text-white px-[45px] mx-auto py-[15px] text-[18px] leading-6 font-bold inline-block rounded-[27px]"
              >
                Send Reset Instructions
              </button>
            </div>
          </form>

          <p className="text-base text-center">
            <span onClick={() => dispatch(showModal('LOGIN_MODAL'))} className="font-bold text-[#832EE6] cursor-pointer">Back to login</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
import React, { useRef, useState, useEffect } from 'react';
import uploadImagePlaceholder from 'src/assets/svg/featuredimage.svg';
import deleteIcon from 'src/assets/svg/delete.svg';
import axios from 'src/api/axios';
import { NotificationManager } from 'react-notifications';

const ImageUpload = ({ initialImage, onImageChange }) => {
  const fileInputReference = useRef(null);
  const [selectedImage, setSelectedImage] = useState(initialImage || null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [uploadProgressPercentage, setUploadProgressPercentage] = useState(0);

  useEffect(() => {
    setSelectedImage(initialImage);
  }, [initialImage]);

  const handleUploadClick = () => {
    fileInputReference.current.click();
  };

  const handleImageSelect = async (event) => {
    const imageFile = event.target.files[0];
    if (!imageFile) return;

    const maxFileSize = 5 * 1024 * 1024; // 5MB
    if (imageFile.size > maxFileSize) {
      NotificationManager.error('File size should not exceed 5MB');
      return;
    }

    setIsImageUploading(true);
    setUploadProgressPercentage(0);

    const imageFormData = new FormData();
    imageFormData.append('image', imageFile);

    try {
      const response = await axios.post('api/media/upload-image', imageFormData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgressPercentage(progress);
        }
      });

      const uploadedImageUrl = response.data.data.public_url;
      setSelectedImage(uploadedImageUrl);
      if (onImageChange) {
        onImageChange(response.data.data);
      }
      NotificationManager.success('Image uploaded successfully');
    } catch (error) {
      console.error('Upload error:', error);
      NotificationManager.error(error.response?.data?.message || 'Error uploading image');
    } finally {
      setIsImageUploading(false);
      setUploadProgressPercentage(0);
    }
  };

  const handleImageDelete = () => {
    setSelectedImage(null);
    if (onImageChange) {
      onImageChange(null);
    }
    NotificationManager.info('Image deleted');
  };

  return (
    <div className="max-w-[400px] w-full">
      <div className="flex justify-between mb-4">
        <p className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="small_size">
          Featured image
        </p>
        <div
          className='rounded-[5px] py-1.5 px-8 bg-[#832EE61C] text-[#832EE6] text-[13px] cursor-pointer'
          onClick={handleUploadClick}
        >
          Upload
        </div>
      </div>
      <div className="flex items-center justify-center w-full">
        <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-[224px] border border-[#DEDEDE] border-dashed rounded-[3px] cursor-pointer bg-white relative group">
          {selectedImage ? (
            <>
              <img src={selectedImage} alt="Selected preview" className='w-full max-w-full max-h-[224px] object-contain' />
              <button 
                onClick={(e) => {
                  e.preventDefault();
                  handleImageDelete();
                }}
                className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 md:opacity-70 hover:opacity-100"
              >
                <img src={deleteIcon} alt="delete" className="w-4 h-4 brightness-0 invert" />
              </button>
            </>
          ) : (
            <img src={uploadImagePlaceholder} alt="Upload placeholder" />
          )}
          {isImageUploading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="w-3/4">
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block text-white">
                        {uploadProgressPercentage}%
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-white bg-opacity-20">
                    <div 
                      style={{ width: `${uploadProgressPercentage}%` }}
                      className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-600 transition-all duration-300"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <input
            id="dropzone-file"
            type="file"
            className="hidden"
            accept="image/png, image/jpeg"
            onChange={handleImageSelect}
            ref={fileInputReference}
          />
        </label>
      </div>
    </div>
  );
};

export default ImageUpload;

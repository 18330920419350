import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from 'react-redux';


const NavItem = ({to, children, className = ''}) => {
    return (
        <NavLink to={to} className={className}>
      {({isActive}) => (
        <div className={`flex hover:font-bold mb-3 pl-5 border-l-4 ${isActive ? 'font-bold border-[#832EE6]' : 'border-transparent'}`}>
          <span className={`my-auto text-base text-[17px] ${isActive ? 'text-[#832EE6]' : 'text-black'}`}>
            {children}
          </span>
        </div>
      )}
    </NavLink>
  );
};

const UserSidebar = () => {
  const { user } = useSelector(state => state.auth);
  const [sidebarOpen, setSidebarOpen] = useState(false);

    // Sidebar
    const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
      // document.body.classList.toggle('overflow-hidden');
      const overlay = document.getElementById('sidebar-overlay');
      if (overlay) {
        overlay.classList.toggle('hidden');
      }
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        const sidebar = document.getElementById('user-sidebar');
        if (sidebar && !sidebar.contains(event.target) && sidebarOpen) {
          setSidebarOpen(false);
          document.body.classList.remove('overflow-hidden');
          const overlay = document.getElementById('sidebar-overlay');
          if (overlay) {
            overlay.classList.add('hidden');
          }
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [sidebarOpen]);
  
  return (
    <div className="">
      <button onClick={toggleSidebar} className='pl-5 bg-[#832EE6] rounded-r-full inline-block md:hidden fixed top-[15vh] left-0 z-10'>
        {user.avatar ? (
                  <img src={user.avatar} alt="profile" className="rounded-full bg-[#404040] w-[54px] h-[54px] flex" />
              ) : (
                  <p className='rounded-full bg-[#404040] w-[54px] h-[54px] flex'>
                      <span className='m-auto text-white font-bold text-[23px]'>{user.first_name?.charAt(0) + user.last_name?.charAt(0)}</span>
                  </p>
              )}
      </button>
      <div id='sidebar-overlay' className='bg-gray-900/50 dark:bg-gray-900/80 fixed hidden inset-0 z-30'></div>
      <div id="user-sidebar" className={`${!sidebarOpen ? '-translate-x-full' : ''} max-w-[332px] w-full bg-white border border-[#EBEBEB] rounded-r-lg md:rounded-lg py-5 fixed md:sticky top-[13vh] left-0 z-40 transition-transform  md:translate-x-0 overflow-hidden`}>
        <div className='flex px-5 mb-5'>
            {user.avatar ? (
                <img src={user.avatar} alt="profile" className="rounded-full bg-[#404040] w-[54px] h-[54px] flex mr-4" />
            ) : (
                <p className='rounded-full bg-[#404040] w-[54px] h-[54px] flex mr-4'>
                    <span className='m-auto text-white font-bold text-[23px]'>{user.first_name?.charAt(0) + user.last_name?.charAt(0)}</span>
                </p>
            )}
          <div>
            <p className='inline-block text-base'>Hello,</p>
            <h3 className='text-[24px] leading-[30px]'>{user.first_name} {user.last_name}</h3>
          </div>
        </div>
        <div className="px-5">
          <span className="block w-full h-[1px] bg-[#DEDEDE]"></span>
        </div>
        <div className='py-2 md:py-[30px]'>
          <NavItem to="/my/settings">
            My Account
          </NavItem>
          <NavItem to="/my/ads">
            My Adverts
          </NavItem>
        </div>
        <div className="px-5">
          <span className="block w-full h-[1px] bg-[#DEDEDE]"></span>
        </div>
        <NavItem to="/logout" className="pt-5 block">
          Log out
        </NavItem>
      </div>
    </div>
  );
};

export default UserSidebar; 
import React from 'react';

const Pagination = ({ meta, onPageChange }) => {
    return (
        <div className="flex items-center justify-center gap-4 mt-8">
            <button
                onClick={() => onPageChange(meta.current_page - 1)}
                className={`flex items-center justify-center w-8 h-8 ${meta.current_page <= 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-700 hover:text-purple-600'}`}
                disabled={meta.current_page <= 1}
            >
                ‹
            </button>

            {[...Array(meta.last_page)].map((_, index) => {
                const pageNumber = index + 1;
                const isCurrentPage = pageNumber === meta.current_page;
                
                if (pageNumber === 1 || 
                    pageNumber === meta.last_page || 
                    (pageNumber >= meta.current_page - 1 && pageNumber <= meta.current_page + 1)) {
                    return (
                        <button
                            key={pageNumber}
                            onClick={() => onPageChange(pageNumber)}
                            className={`flex items-center justify-center w-8 h-8 rounded-full
                                ${isCurrentPage ? 'bg-purple-600 text-white' : 'text-gray-700 hover:text-purple-600'}`}
                        >
                            {pageNumber}
                        </button>
                    );
                } else if (pageNumber === meta.current_page - 2 || pageNumber === meta.current_page + 2) {
                    return <span key={pageNumber} className="px-1">...</span>;
                }
                return null;
            })}

            <button
                onClick={() => onPageChange(meta.current_page + 1)}
                className={`flex items-center justify-center w-8 h-8 ${meta.current_page >= meta.last_page ? 'text-gray-300 cursor-not-allowed' : 'text-gray-700 hover:text-purple-600'}`}
                disabled={meta.current_page >= meta.last_page}
            >
                ›
            </button>
        </div>
    );
};

export default Pagination; 
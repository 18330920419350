import React from 'react';

const ColorBox = ({ name, value, spanText, onChange, defaultChecked = false, type = 'radio' }) => {
    if (!name || !value) {
        console.error('Missing required props: name and value are required.');
        return null;
    }
    const colorMap = {
        black: '#000000',
        white: '#ffffff',
        cream: '#EFE5D5',
        grey: '#D6D6D6',
        gold: '#F5CE8F',
        mixed: 'linear-gradient(to right, #8B4513, #000000)',
        brown: '#6F574B',
        other: '#F0D4CD',
    };


    const boxStyle = {
        background: colorMap[value.toLowerCase()] || value,
        border: value.toLowerCase() === 'white' ? '2px solid #DBDBDB' : '2px solid white',
    };
    
    return (
        <label className="text-base font-medium text-[#0D0D0D] dark:text-gray-300 flex relative cursor-pointer">
            <input 
                type={type} 
                name={name} 
                value={value} 
                className="opacity-0 absolute left-0 peer" 
                onChange={onChange} 
                defaultChecked={defaultChecked}
            />
            <div style={boxStyle} className='my-auto w-5 h-5 rounded-sm border-2 peer-checked:ring-2 peer-checked:ring-purple-500'></div>
            <span className='pl-2'>{spanText}</span>
        </label>
    );
};

export default ColorBox;

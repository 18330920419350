import React, { useState } from 'react';
import axios from 'src/api/axios';
import { NotificationManager } from 'react-notifications';

const PhoneVerificationModal = ({ isOpen, onClose, phoneNumber, onVerificationSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState('send'); // 'send' or 'verify'
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);

  const handleSendCode = async () => {
    if (!phoneNumber) {
      NotificationManager.error('Please enter a phone number first');
      return;
    }

    setIsSubmitting(true);
    try {
      const response = await axios.post('/api/my/phone/send-code', {
        phone_number: phoneNumber
      }, {
        disableNotification: true
      });
      setStep('verify');
      NotificationManager.success(response.data.message);
    } catch (error) {
      NotificationManager.error(error.response?.data?.message || 'Failed to send code');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCodeChange = (index, value) => {
    if (!/^\d?$/.test(value)) return; // Allow only single digits or empty

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    // Auto-focus next input
    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name=code-${index + 1}]`);
      if (nextInput) nextInput.focus();
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    const code = verificationCode.join('');
    if (code.length !== 6) {
      NotificationManager.error('Please enter the complete code');
      return;
    }
    
    setIsSubmitting(true);
    try {
      const response = await axios.post('/api/my/phone/verify', {
        phone_number: phoneNumber,
        code: code
      }, {
        disableNotification: true
      });
      NotificationManager.success(response.data.message);
      onVerificationSuccess();
      onClose();
    } catch (error) {
      NotificationManager.error(error.response?.error || 'Invalid verification code');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 animate-fadeIn" onClick={onClose}>
      <div className="bg-white rounded-lg p-6 w-full max-w-md mx-auto mt-20 animate-slideDown relative" onClick={e => e.stopPropagation()}>
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        <h2 className="text-xl font-semibold mb-4">Verify Phone Number</h2>
        
        {step === 'send' ? (
          <div className="text-center">
            <div className="bg-gray-50 rounded-lg shadow-sm mb-6">
              <p className="text-gray-600 mb-2">
                We'll send a verification code to:
              </p>
              <p className="text-lg font-semibold text-gray-800">{phoneNumber}</p>
            </div>
            
            <div className="mt-2">
              <button
                type="button"
                onClick={handleSendCode}
                disabled={isSubmitting}
                className={`
                  px-6 py-3 
                  bg-[#832EE6] text-white 
                  rounded-lg
                  font-medium
                  transition-all
                  hover:bg-purple-700 hover:shadow-lg
                  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
                  relative
                  ${isSubmitting ? 'cursor-not-allowed opacity-75' : ''}
                `}
              >
                {isSubmitting ? (
                  <>
                    <span className="opacity-0">Send Code</span>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                    </div>
                  </>
                ) : (
                  'Send Verification Code'
                )}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-4 text-center">
                  Enter the verification code that we sent to <span className="font-semibold text-gray-800">{phoneNumber}</span>
                </label>
                <div className="flex justify-center gap-2">
                  {verificationCode.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      name={`code-${index}`}
                      maxLength="1"
                      className="w-12 h-12 text-center border-2 border-gray-300 rounded-lg focus:border-primary focus:ring-primary text-lg"
                      value={digit}
                      onChange={(e) => handleCodeChange(index, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Backspace' && !digit && index > 0) {
                          const prevInput = document.querySelector(`input[name=code-${index - 1}]`);
                          if (prevInput) {
                            prevInput.focus();
                            handleCodeChange(index - 1, '');
                          }
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-center space-x-3">
              <button
                type="button"
                onClick={() => setStep('send')}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
                disabled={isSubmitting}
              >
                Back
              </button>
              <button
                type="button"
                onClick={handleVerifyCode}
                disabled={isSubmitting}
                className={`px-4 py-2 bg-[#832EE6] text-white rounded-md hover:bg-purple-700 relative ${
                  isSubmitting ? 'cursor-not-allowed opacity-75' : ''
                }`}
              >
                {isSubmitting ? (
                  <>
                    <span className="opacity-0">Verify</span>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white"></div>
                    </div>
                  </>
                ) : (
                  'Verify'
                )}
              </button>
            </div>
          </div>
        )}
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        @keyframes slideDown {
          from { 
            opacity: 0;
            transform: translateY(-100px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        @keyframes slideUp {
          from {
            opacity: 1;
            transform: translateY(0);
          }
          to { 
            opacity: 0;
            transform: translateY(-100px);
          }
        }

        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out;
        }

        .animate-slideDown {
          animation: slideDown 0.3s ease-out;
        }

        .animate-slideUp {
          animation: slideUp 0.3s ease-out;
        }
      `}</style>
    </div>
  );
};

export default PhoneVerificationModal;
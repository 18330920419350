import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import shareonfacebook from 'src/assets/svg/shareonfacebook.svg';
import shareoninstagram from 'src/assets/svg/shareoninstagram.svg';
import shareonpinterest from 'src/assets/svg/shareonpinterest.svg';

const ShareButton = ({ platform }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const getIcon = () => {
        switch (platform) {
            case 'facebook':
                return shareonfacebook;
            case 'instagram':
                return shareoninstagram;
            case 'pinterest':
                return shareonpinterest;
            default:
                return shareonfacebook;
        }
    };

    const copyToClipboard = (text) => {
        try {
            navigator.clipboard.writeText(text);
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000);
        } catch (error) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000);
        }
    };

    const handleShare = () => {
        const url = window.location.href;
        const title = document.title;
        const hashtags = '#cat #sphynx #sphynxplace';

        switch (platform) {
            case 'facebook':
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&hashtag=${encodeURIComponent(hashtags)}`, 
                    'facebook-share-dialog', 
                    'width=626,height=436');
                break;

            case 'instagram':
                if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    window.open(`instagram://story-camera`, '_blank');
                } else {
                    const instagramText = `${title}\n${url}\n${hashtags}`;
                    copyToClipboard(instagramText);
                    NotificationManager.info('Text copied! You can paste it on Instagram.');                }
                break;

            case 'pinterest':
                const firstImage = document.querySelector('img')?.src || '';
                window.open(
                    `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&media=${encodeURIComponent(firstImage)}&description=${encodeURIComponent(title + ' ' + hashtags)}`,
                    'pinterest-share-dialog',
                    'width=750,height=550'
                );
                break;

            default:
                break;
        }
    };

    return (
        <div className="relative">
            <button onClick={handleShare}>
                <img src={getIcon()} alt={`share on ${platform}`} />
            </button>
            {showTooltip && (
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded whitespace-nowrap">
                    Link copied!
                </div>
            )}
        </div>
    );
};

export default ShareButton;
import { React, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import 'react-notifications/lib/notifications.css';

import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';
import LoginForm from '../components/form/LoginForm';

const Login = () => {
 

  return (
    <>
      <Header />
      <div className="container mx-auto">
        <LoginForm />
      </div>
      <Footer />
    </>
  )
}

export default Login
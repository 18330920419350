import React, { useState, useEffect } from 'react';

const PriceFilter = ({ initialFrom, initialTo, onFilter }) => {
  const minPrice = 0;
  const maxPrice = 10000;
  
  const [priceFrom, setPriceFrom] = useState(initialFrom || minPrice);
  const [priceTo, setPriceTo] = useState(initialTo || maxPrice);
  const [tempPriceFrom, setTempPriceFrom] = useState(initialFrom || minPrice);
  const [tempPriceTo, setTempPriceTo] = useState(initialTo || maxPrice);
  const [isDragging, setIsDragging] = useState(null);

  useEffect(() => {
    const handleTouchMove = (e) => {
      if (!isDragging) return;
      
      const touch = e.touches[0];
      const slider = document.querySelector('.slider-track');
      const rect = slider.getBoundingClientRect();
      const percent = Math.min(Math.max((touch.clientX - rect.left) / rect.width, 0), 1);
      const value = Math.round(minPrice + percent * (maxPrice - minPrice));
      
      if (isDragging === 'from') {
        if (value < tempPriceTo) {
          setTempPriceFrom(Math.min(value, maxPrice));
        }
      } else {
        if (value > tempPriceFrom) {
          setTempPriceTo(Math.min(value, maxPrice));
        }
      }
      e.preventDefault(); // Prevent scrolling while dragging
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      
      const slider = document.querySelector('.slider-track');
      const rect = slider.getBoundingClientRect();
      const percent = Math.min(Math.max((e.clientX - rect.left) / rect.width, 0), 1);
      const value = Math.round(minPrice + percent * (maxPrice - minPrice));
      
      if (isDragging === 'from') {
        if (value < tempPriceTo) {
          setTempPriceFrom(Math.min(value, maxPrice));
        }
      } else {
        if (value > tempPriceFrom) {
          setTempPriceTo(Math.min(value, maxPrice));
        }
      }
    };

    const handleTouchEnd = () => {
      if (isDragging) {
        setIsDragging(null);
      }
    };

    const handleMouseUp = () => {
      if (isDragging) {
        setIsDragging(null);
      }
    };

    // Add touch event listeners
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchend', handleTouchEnd);
    // Keep mouse event listeners for desktop
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, tempPriceFrom, tempPriceTo]);

  const handleFilter = () => {
    setPriceFrom(tempPriceFrom);
    setPriceTo(tempPriceTo);
    
    const filterData = {};
    
    if (tempPriceFrom > minPrice) {
      filterData.price_from = tempPriceFrom;
    }
    
    if (tempPriceTo < maxPrice) {
      filterData.price_to = tempPriceTo;
    }

    onFilter(filterData);
  };

  const getLeftPercent = () => {
    return ((tempPriceFrom - minPrice) / (maxPrice - minPrice)) * 100;
  };

  const getRightPercent = () => {
    return ((tempPriceTo - minPrice) / (maxPrice - minPrice)) * 100;
  };

  const handleInputChange = (e, type) => {
    let value = parseInt(e.target.value) || 0;
    value = Math.min(value, maxPrice); // Ensure value doesn't exceed maxPrice
    
    if (type === 'from' && value < tempPriceTo) {
      setTempPriceFrom(value);
    } else if (type === 'to' && value > tempPriceFrom) {
      setTempPriceTo(value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleFilter();
    }
  };

  return (
    <>
      <div className="relative h-2 bg-purple-200 rounded-full slider-track touch-none mb-6">
        <div 
          className="absolute h-2 bg-purple-400 rounded-full" 
          style={{
            left: `${getLeftPercent()}%`,
            width: `${getRightPercent() - getLeftPercent()}%`
          }}
        />
        <div 
          className="absolute w-6 h-6 bg-purple-600 rounded-full -mt-2 -ml-3 cursor-pointer touch-none" 
          style={{left: `${getLeftPercent()}%`}}
          onMouseDown={() => setIsDragging('from')}
          onTouchStart={() => setIsDragging('from')}
        />
        <div 
          className="absolute w-6 h-6 bg-purple-600 rounded-full -mt-2 -ml-3 cursor-pointer touch-none" 
          style={{left: `${getRightPercent()}%`}}
          onMouseDown={() => setIsDragging('to')}
          onTouchStart={() => setIsDragging('to')}
        />
      </div>
      <div className="flex flex-wrap items-center gap-2 mb-2">
        <span className="text-gray-500">from</span>
        <input
          type="number" 
          id="price_from"
          value={tempPriceFrom === minPrice ? '' : tempPriceFrom}
          onChange={(e) => handleInputChange(e, 'from')}
          onKeyPress={handleKeyPress}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-[50px] p-2 appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          placeholder={`${minPrice}`}
          min={minPrice}
          max={tempPriceTo}
        />
        <span className="text-gray-500">to</span>
        <input
          type="number"
          id="price_to" 
          value={tempPriceTo === maxPrice ? '' : tempPriceTo}
          onChange={(e) => handleInputChange(e, 'to')}
          onKeyPress={handleKeyPress}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-[50px] p-2 appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          placeholder={`${maxPrice}`}
          min={tempPriceFrom}
          max={maxPrice}
        />
        <button
          type="button"
          onClick={handleFilter}
          className="bg-gray-700 text-white text-sm font-medium px-4 py-2 rounded hover:bg-gray-800"
        >
          FILTER
        </button>
      </div>
    </>
  );
};

export default PriceFilter;
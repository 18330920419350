import React from 'react';
import Header from 'src/layouts/Header';
import Footer from 'src/layouts/Footer';

const TermsAndConditions = () => {
  return (
    <>
    <Header />
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl md:text-4xl font-bold mb-8">Terms and Conditions</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">1. Introduction</h2>
        <p className="mb-4">
          Welcome to sphynxplace.com ("the Platform"). By accessing or using our website, you agree to comply with these 
          Terms and Conditions ("Terms"). If you do not agree with these Terms, please refrain from using the Platform.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">2. Scope of Services</h2>
        <ul className="list-disc pl-6">
          <li>sphynxplace.com is an online marketplace that connects buyers and sellers of Sphynx cats.</li>
          <li>Users may post ads from any country, but it is their responsibility to ensure compliance with local laws 
              and regulations regarding pet transactions.</li>
          <li>sphynxplace.com does not mediate, verify, or guarantee the accuracy or legality of ads or transactions 
              conducted on the Platform.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">3. User Responsibilities</h2>
        <h3 className="text-xl font-semibold mb-2">Compliance with Local Laws:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Users are solely responsible for ensuring their activities comply with the laws of their country or region.</li>
          <li>sphynxplace.com is not liable for any legal disputes or issues arising from non-compliance with local laws.</li>
        </ul>
        
        <h3 className="text-xl font-semibold mb-2">Accuracy of Information:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Users must ensure that all information provided in their ads is truthful, accurate, and complete.</li>
          <li>Misleading or fraudulent ads will be removed.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Buyer Responsibility:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Buyers must independently verify the health, documentation, and condition of pets before completing a transaction.</li>
          <li>sphynxplace.com strongly encourages buyers to meet sellers in person and confirm all details.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">4. Account Registration and Use</h2>
        <ul className="list-disc pl-6">
          <li>Users must be at least 18 years old to register on sphynxplace.com.</li>
          <li>Users are responsible for maintaining the confidentiality of their account credentials.</li>
          <li>sphynxplace.com reserves the right to suspend or terminate accounts that violate these Terms.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">5. Posting Ads</h2>
        <h3 className="text-xl font-semibold mb-2">Ad Requirements:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Users may post ads with detailed information about their pets, including gender, age, health status, and temperament.</li>
          <li>Ads may include photos and optional pricing information.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Verification:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>Users may add and verify their phone number in the Contact Details section of their account, but it is not mandatory. Verified phone numbers can be displayed in ads at the user's discretion.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Prohibited Content:</h3>
        <ul className="list-disc pl-6">
          <li>Ads containing false, offensive, or illegal content are strictly prohibited and will be removed.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">6. Prohibited Activities</h2>
        <p className="mb-2">Users agree not to:</p>
        <ul className="list-disc pl-6">
          <li>Post fraudulent, misleading, or inappropriate ads.</li>
          <li>Use sphynxplace.com for illegal purposes.</li>
          <li>Interfere with the functionality or security of the Platform.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">7. Disclaimer of Liability</h2>
        <h3 className="text-xl font-semibold mb-2">Accuracy of Ads:</h3>
        <ul className="list-disc pl-6 mb-4">
          <li>sphynxplace.com does not verify or guarantee the accuracy, legality, or authenticity of ads.</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2">Transactions:</h3>
        <ul className="list-disc pl-6">
          <li>The Platform is not involved in transactions between buyers and sellers and is not liable for any disputes, 
              damages, or losses arising from such transactions.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">8. Governing Law</h2>
        <p className="mb-4">
          These Terms are governed by the laws of England and Wales. Any disputes shall be resolved in the courts 
          of England and Wales.
        </p>
      </section>
    </div>
    <Footer />
    </>
  );
};

export default TermsAndConditions;
import React, { useEffect, useState, useCallback } from "react";
import { HiChevronDown, HiFilter } from "react-icons/hi";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import queryString from "query-string";
import Header from "src/layouts/Header";
import Footer from "src/layouts/Footer";
import ArticleList from "src/components/section/ArticleList";
import Pagination from "src/components/common/Pagination";
import axios from "src/api/axios";
import { NotificationManager } from "react-notifications";
import noFavorites from "src/assets/svg/nofavourites.svg";
import ColorBox from 'src/components/ColorBox'
import PriceFilter from 'src/components/PriceFilter';

const Search = () => {
  const navigate = useNavigate();
  const [adOptions, setAdOptions] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [articles, setArticles] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [showAllFilters, setShowAllFilters] = useState(false);

  useEffect(() => {
    const openDropdowns = Array.from(searchParams.keys());
    setOpenDropdowns(openDropdowns);
  }, []);

  const handleChange = (e) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(e.target.name, e.target.value);
    setSearchParams(newParams);
  }

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const params = Object.fromEntries(searchParams.entries());

        // Convert comma-separated values into array format for query string
        const queryParams = new URLSearchParams();

        // Handle array parameters
        ['color', 'age', 'temperament', 'compatibility'].forEach(param => {
          if (params[param]) {
            params[param].split(',').forEach(value => {
              queryParams.append(`${param}[]`, value);
            });
          }
        });

        // Add non-array parameters
        Object.entries(params).forEach(([key, value]) => {
          if (!['age', 'color', 'temperament', 'compatibility'].includes(key)) {
            queryParams.append(key, value);
          }
        });

        const response = await axios.get("/api/search/articles?" + queryParams.toString());
        setResults(response.data);
        setArticles(response.data.data);
        setMeta(response.data.meta);
      } catch (error) {
        console.error("Error fetching articles:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [searchParams]);

  useEffect(() => {
    fetchAdOptions();
  }, [searchParams]);

    const fetchAdOptions = useCallback(async () => {
    try {
      const response = await axios.get(`/api/resources/search${searchParams.get('ad_type') ? `?ad_type=${searchParams.get('ad_type')}` : ''}`)
      setAdOptions(response.data.data)
      // Reset filters that don't have corresponding fields in the response
      const newParams = new URLSearchParams(searchParams);
      const availableFilters = Object.keys(response.data.data);
      ['color', 'age', 'temperament', 'compatibility', 'training-level', 'energy-level', 'grooming-level', 'advertiser-type'].forEach(filter => {
        if (!availableFilters.includes(filter) && newParams.has(filter)) {
          newParams.delete(filter);
        }
      });

      if (newParams.toString() !== searchParams.toString()) {
        setSearchParams(newParams);
      }
    } catch (error) {
      NotificationManager.error('Error fetching ad options')
    }
  }, [searchParams.get('ad_type')])

  const handleChangeFilter = (e) => {
    // Ignorišemo promene koje dolaze od price inputa
    if (e.target.id === 'price_from' || e.target.id === 'price_to') {
      return;
    }
    
    // Ostatak logike za ostale filtere
    const { name, value, type } = e.target;
    const newParams = new URLSearchParams(searchParams);
    
    if (type === 'checkbox') {
      let values = searchParams.get(name)?.split(',') || [];
      
      if (e.target.checked) {
        values.push(value);
      } else {
        values = values.filter(v => v !== value);
      }
      
      if (values.length) {
        const otherParams = Array.from(searchParams.entries())
          .filter(([key]) => key !== name)
          .map(([key, value]) => `${key}=${value}`)
          .join('&');
          
        const param = `${name}=${values.join(',')}`;
        const query = otherParams ? `${param}&${otherParams}` : param;
        
        navigate(`/search?${query}`);
        return;
      } else {
        newParams.delete(name);
      }
    } else {
      if (value) {
        newParams.set(name, value);
      } else {
        newParams.delete(name);
      }
    }
    
    setSearchParams(newParams);
  };

  const handlePriceFilter = (prices) => {
    const newParams = new URLSearchParams(searchParams);
    if (prices.price_from) {
      newParams.set('price_from', prices.price_from);
    } else {
      newParams.delete('price_from');
    }
    if (prices.price_to) {
      newParams.set('price_to', prices.price_to);
    } else {
      newParams.delete('price_to');
    }
    setSearchParams(newParams);
  };

  const handlePageChange = (page) => {
    setSearchParams(prev => ({...Object.fromEntries(prev.entries()), page}));
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    setSearchParams(prev => {
      const newParams = new URLSearchParams(prev);
      if (value) {
        newParams.set('sort_by', value);
      } else {
        newParams.delete('sort_by');
      }
      return newParams;
    });
  };

  // Sidebar
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    document.body.classList.toggle('overflow-hidden');
    const overlay = document.getElementById('sidebar-overlay');
    if (overlay) {
      overlay.classList.toggle('hidden');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const sidebar = document.getElementById('sidebar-multi-level-sidebar');
      if (sidebar && !sidebar.contains(event.target) && sidebarOpen) {
        setSidebarOpen(false);
        document.body.classList.remove('overflow-hidden');
        const overlay = document.getElementById('sidebar-overlay');
        if (overlay) {
          overlay.classList.add('hidden');
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen]);

  // dropdown
  useEffect(() => {
    // Check which dropdowns should be visible initially based on checked elements
    const dropdowns = document.querySelectorAll('.dropdown-content');
    dropdowns.forEach((dropdown, index) => {
      const checkedElements = dropdown.querySelectorAll('input:checked');
      if (checkedElements.length > 0) {
        dropdown.classList.remove('hidden');
      }
    });
  }, []);
  
  const toggleDropdown = (name) => {
    const dropdown = document.querySelector(`[data-dropdown="${name}"]`);
    if (dropdown) {
      dropdown.classList.toggle('hidden');
    }
  };

  // Add this function to reset all form inputs
  const resetFormInputs = useCallback(() => {
    const form = document.querySelector('form');
    if (form) {
      const inputs = form.querySelectorAll('input[type="radio"], input[type="checkbox"]');
      inputs.forEach(input => {
        input.checked = false;
      });
    }
  }, []);

  // Add effect to handle URL changes and reset form when needed
  useEffect(() => {
    const params = new URLSearchParams(searchParams);
    if (params.toString() === '') {
      resetFormInputs();
    }
  }, [searchParams, resetFormInputs]);

  // Add this helper function to get readable filter names
  const getFilterLabel = (key, value) => {
    // Special case for ad_type
    if (key === 'ad_type') {
      return `${adOptions[key]?.options[value] || value}`;
    }
    
    if (!adOptions[key]) return value;
    return `${key.split('-').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ')}: ${adOptions[key].options[value] || value}`;
  };

  // Add this function to remove individual filters
  const removeFilter = (key) => {
    const newParams = new URLSearchParams(searchParams);
    if (key === 'price') {
      newParams.delete('price_from');
      newParams.delete('price_to');
    } else {
      newParams.delete(key);
    }
    setSearchParams(newParams);
  };

  // Update the getActiveFilters function
  const getActiveFilters = () => {
    const filters = [];
    const groupedFilters = {};

    for (const [key, value] of searchParams.entries()) {
      // Skip pagination and sorting params
      if (['page', 'sort_by'].includes(key)) continue;
      
      // Group price filters
      if (key === 'price_from' || key === 'price_to') {
        if (!groupedFilters.price) {
          groupedFilters.price = { from: null, to: null };
        }
        if (key === 'price_from') {
          groupedFilters.price.from = value;
        } else {
          groupedFilters.price.to = value;
        }
        continue;
      }

      // Group values by key for array parameters
      if (value.includes(',')) {
        const values = value.split(',');
        // Remove the key name from individual values for array parameters
        const formattedValues = values.map(v => adOptions[key]?.options[v] || v);
        
        // Format the label with the key name only once
        const label = key === 'ad_type' 
          ? `Type of Listing: ${formattedValues.join(', ')}`
          : `${key.split('-').map(word => 
              word.charAt(0).toUpperCase() + word.slice(1)
            ).join(' ')}: ${formattedValues.join(', ')}`;

        filters.push({
          key,
          value,
          label
        });
      } else {
        filters.push({
          key,
          value,
          label: key === 'ad_type' 
            ? `Type of Listing: ${getFilterLabel(key, value)}` 
            : getFilterLabel(key, value)
        });
      }
    }

    // Add price filter if exists
    if (groupedFilters.price) {
      const { from, to } = groupedFilters.price;
      if (from || to) {
        filters.push({
          key: 'price',
          value: `${from || ''}-${to || ''}`,
          label: `Price: ${from ? `from $${from}` : ''}${from && to ? ' - ' : ''}${to ? `to $${to}` : ''}`
        });
      }
    }
    
    return filters;
  };

  return (
    <>
      <Header />
      <div className='bg-[#F7F7F7] pt-10 pb-10'>
        <div className="container mx-auto px-2 relative">
          {/* <button 
            onClick={() => document.getElementById('debug-info').classList.toggle('hidden')}
            className="mb-4 px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Toggle Debug Info
          </button>
           */}
          <div id="debug-info" className="hidden">
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">Ad Options:</h2>
              <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
                {JSON.stringify(adOptions, null, 2)}
              </pre>
            </div>
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">Search Parameters:</h2>
              <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
                {JSON.stringify(searchParams.get('age'), null, 2)}
              </pre>
            </div>
            <div className="mb-4">
              <h2 className="text-lg font-bold mb-2">Meta:</h2>
              <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
                {JSON.stringify(meta, null, 2)}
              </pre>
            </div>
          </div>
        </div>
        <div className='container mx-auto px-2 relative md:flex md:gap-[15px] 2xl:gap-[32px] md:justify-between'>
          <aside id="sidebar-multi-level-sidebar" className={`fixed h-screen md:max-h-[85vh] flex flex-col md:sticky top-0 left-0 z-40 w-66 transition-transform ${!sidebarOpen ? '-translate-x-full' : ''} md:translate-x-0 md:min-w-[330px] md:rounded-lg overflow-hidden`} aria-label="Sidebar">
            <div className='flex gap-4 px-6 py-4 justify-between bg-[#832EE6] text-white items-center'>
              <h3 className='font-bold text-xl'>Filters</h3>
              <Link to="/search">Clear all</Link>
            </div>
            <div className="h-full px-3 py-4 overflow-y-auto bg-white dark:bg-gray-800">
              <form action="" onChange={handleChangeFilter}>
                <ul className="space-y-2 font-medium">
                  <li>
                    <button type="button" className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Type of Listing</span>
                    </button>
                    <ul data-dropdown="type" className={`dropdown-content py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions?.ad_type?.options || {}).map(([value, label], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                          <input name='ad_type' defaultChecked={searchParams.get('ad_type') === value ? true : false} id={`ad-type-${index}`} type="radio" value={value} className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600" />
                          <label htmlFor={`ad-type-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
                        </div>
                      </li>
                      ))}
                    </ul>
                  </li>
                  <li>
                    <button type="button" className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Price</span>
                    </button>
                    <ul className="py-2 pl-4 space-y-2">
                      <li>
                        <PriceFilter 
                          initialFrom={searchParams.get('price_from')}
                          initialTo={searchParams.get('price_to')}
                          onFilter={handlePriceFilter}
                        />
                      </li>
                    </ul>
                  </li>
                  {adOptions?.color?.options && (
                    <li>
                      <button type="button" className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                        <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Pet Color</span>
                      </button>
                      <ul data-dropdown="color" className={`dropdown-content py-2 pl-4 grid grid-cols-2 gap-2`}>
                        {Object.entries(adOptions.color.options).map(([key, value], index) => (
                          <ColorBox key={index} name="color" type="checkbox" value={key} spanText={value} onChange={handleChange} defaultChecked={searchParams.get('color') === key} />
                        ))}
                      </ul>
                    </li>
                  )}
                  {adOptions?.age?.options && (
                    <li>
                      <button type="button" className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Age</span>
                    </button>
                    <ul data-dropdown="age" className={`dropdown-content py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions.age.options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                            name={adOptions.age.slug} 
                            defaultChecked={searchParams.get(adOptions.age.slug)?.split(',').includes(key)} 
                            id={`age-checkbox-${index}`} 
                            type={adOptions.age.type}
                            value={key}
                            className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label htmlFor={`age-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                        </div>
                      </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.gender?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('gender')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Gender</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="gender" className={`dropdown-content ${openDropdowns.includes('gender') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions.gender.options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                          <input name="gender" value={key} defaultChecked={searchParams.get('gender') === key ? true : false} id={`gender-checkbox-${index}`} type="radio" className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600" />
                          <label htmlFor={`gender-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                        </div>
                      </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.temperament?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('temperament')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Temperament</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="temperament" className={`dropdown-content ${openDropdowns.includes('temperament') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions.temperament.options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                              name={adOptions.temperament.slug} 
                              value={key}
                              defaultChecked={searchParams.get(adOptions.temperament.slug)?.split(',').includes(key)}
                              id={`temperament-checkbox-${index}`}
                              type={adOptions.temperament.type}
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`temperament-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.compatibility?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('compatibility')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Compatibility</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="compatibility" className={`dropdown-content ${openDropdowns.includes('compatibility') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions.compatibility.options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                              name={adOptions.compatibility.slug} 
                              value={key}
                              defaultChecked={searchParams.get(adOptions.compatibility.slug)?.split(',').includes(key)}
                              id={`compatibility-checkbox-${index}`}
                              type={adOptions.compatibility.type}
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`compatibility-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.['training-level']?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('training-level')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Training</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="training-level" className={`dropdown-content ${openDropdowns.includes('training-level') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions['training-level'].options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                              name='training-level'
                              value={key}
                              defaultChecked={searchParams.get(adOptions['training-level'].slug)?.split(',').includes(key)}
                              id={`training-checkbox-${index}`}
                              type='radio'
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`training-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.['energy-level']?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('energy-level')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Energy</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="energy-level" className={`dropdown-content ${openDropdowns.includes('energy-level') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions['energy-level'].options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                              name="energy-level" 
                              value={key}
                              defaultChecked={searchParams.get('energy-level') === key ? true : false}
                              id={`energy-checkbox-${index}`}
                              type="radio"
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`energy-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.['grooming-level']?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('grooming-level')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Grooming</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="grooming-level" className={`dropdown-content ${openDropdowns.includes('grooming-level') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions['grooming-level'].options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                              name="grooming-level" 
                              value={key}
                              defaultChecked={searchParams.get('grooming-level') === key ? true : false}
                              id={`grooming-checkbox-${index}`}
                              type="radio"
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`grooming-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  )}
                  {adOptions?.['advertiser-type']?.options && (
                  <li>
                    <button type="button" onClick={() => toggleDropdown('advertiser-type')} className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">
                      <span className="flex-1 ml-3 text-left whitespace-nowrap font-bold">Advertiser Type</span>
                      <HiChevronDown />
                    </button>
                    <ul data-dropdown="advertiser-type" className={`dropdown-content ${openDropdowns.includes('advertiser-type') ? '' : 'hidden'} py-2 pl-4 space-y-2`}>
                      {Object.entries(adOptions['advertiser-type'].options).map(([key, value], index) => (
                        <li key={index}>
                          <div className="flex items-center mr-4">
                            <input 
                              name="advertiser-type" 
                              value={key}
                              defaultChecked={searchParams.get('advertiser-type') === key ? true : false}
                              id={`advertiser-type-checkbox-${index}`}
                              type="radio"
                              className="w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 accent-purple-600 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label htmlFor={`advertiser-type-checkbox-${index}`} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{value}</label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </li>
                  )}
                </ul>
              </form>
            </div>
          </aside>
          <div className="w-full">
              <div className='flex gap-[15px] 2xl:gap-[32px] justify-between mb-2'>
                <button onClick={toggleSidebar} type="button" className="inline-flex items-center h-[43px] py-2 px-4 text-sm text-gray-500 md:hidden rounded-lg bg-white hover:bg-gray-100 focus:outline-none border  border-gray-300 accent-purple-600 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                  <span className="sr-only">Open sidebar</span>
                  <HiFilter className='w-4 h-4 mr-2' />
                  <span className="text-black">Filters</span>
                </button>
                <p className='my-auto hidden md:block'>
                  <span> {loading ? '?' : meta.total}</span> Sphynxs
                </p>
                <div>
                  <select 
                    id="sort" 
                    value={searchParams.get('sort_by') || ''}
                    onChange={handleSortChange}
                    className="bg-gray-50 border border-gray-300 text-sm md:text-base block w-full md:w-[170px] p-2.5 rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">Sort by</option>
                    <option value="price_asc">Price (low to high)</option>
                    <option value="price_desc">Price (high to low)</option>
                    <option value="newest">Newest first</option>
                    <option value="oldest">Oldest first</option>
                  </select>
                  <p className='my-auto md:hidden text-right pt-2'>
                    <span>{meta.total}</span> Sphynxs
                  </p>
                </div>

              </div>

              {/* Update the active filters section with improved mobile styling */}
              {getActiveFilters().length > 0 && (
                <div className="mb-4">
                  <div className="relative">
                    <div className={`flex flex-wrap gap-2 mb-2 ${!showAllFilters && 'overflow-hidden md:overflow-visible h-[32px] md:h-auto'}`}>
                      {getActiveFilters().map((filter, index) => (
                        <span
                          key={index}
                          className={`inline-flex items-center px-2 py-1 md:px-3 md:py-1 rounded-full text-xs md:text-sm bg-purple-100 text-purple-800 ${
                            !showAllFilters && index >= 3 ? 'hidden md:inline-flex' : ''
                          }`}
                        >
                          {filter.label}
                          <button
                            onClick={() => removeFilter(filter.key)}
                            className="ml-1 md:ml-2 hover:text-purple-900 p-1"
                            aria-label="Remove filter"
                          >
                            ×
                          </button>
                        </span>
                      ))}
                    </div>
                    {getActiveFilters().length > 3 && (
                      <button
                        onClick={() => setShowAllFilters(!showAllFilters)}
                        className="text-xs text-purple-600 hover:text-purple-800 underline mt-2 md:hidden"
                      >
                        {showAllFilters ? 'Show less' : `Show more`}
                      </button>
                    )}
                    <button
                      onClick={() => navigate('/search')}
                      className="text-xs md:text-sm text-purple-600 hover:text-purple-800 underline mt-2 ml-4"
                    >
                      Clear all filters
                    </button>
                  </div>
                </div>
              )}

              {loading ? (
                <div className="flex items-center justify-center py-6 md:py-10 min-h-[50vh]">
                  <div className="w-12 h-12 border-4 border-purple-600 rounded-full animate-spin border-t-transparent"></div>
                </div>
              ) : (
                <div className='flex flex-col'>
                  {articles.length > 0 ? (
                    articles.map(article => (
                      <ArticleList key={article.id} article={article} />
                    ))
                  ) : (
                    <div className='flex flex-col'>
                    <img src={noFavorites} alt="nofavorites" className="mx-auto mt-5 md:mt-10 w-[200px] md:w-auto"/>
                    <h2 className="uppercase font-bold text-[20px] md:text-[24px] leading-[28px] md:leading-[35px] my-[30px] md:my-[50px] text-center">No results found</h2>
                      <Link to="/search" className="bg-[#832EE6] hover:bg-purple-800 text-white px-[30px] md:px-[45px] mx-auto py-[12px] md:py-[16px] text-[16px] md:text-[18px] leading-5 md:leading-6 font-bold inline-block rounded-[27px]">Clear filters</Link>
                    </div>
                  )}
                  {meta?.total > 0 && meta?.last_page > 1 && <Pagination meta={meta} onPageChange={handlePageChange} />}
                </div>
              )}

           </div>
        </div>
      </div>
      <div id='sidebar-overlay' className='bg-gray-900/50 dark:bg-gray-900/80 fixed hidden inset-0 z-30'></div>
      <Footer />
    </>
  )
}

export default Search
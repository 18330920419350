import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

// Create instance
let instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// Handle response errors
instance.interceptors.response.use(
  response => response,
  error => {
    const disableNotification = error.config?.disableNotification; // Check for disableNotification parameter

    if (error.response?.status === 429) {
      // Too Many Requests error
      if (!disableNotification) {
        NotificationManager.error(
          'Too many requests. Please wait 1 minute before trying again.',
          'Rate Limit Exceeded',
          6000
        );
      }
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(instance(error.config));
        }, 60000); // Wait 1 minute before retrying
      });
    }

    if (error.response?.data?.error) {
      console.error(error.response.data.error);
      if (!disableNotification) {
        NotificationManager.error(error.response.data.error);
      }
    } else if (error.response?.data?.message) {
      console.error(error.response.data.message);
      if (!disableNotification) {
        NotificationManager.error(error.response.data.message);
      }
    } else {
      console.error(error.message);
      if (!disableNotification) {
        NotificationManager.error(error.message);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
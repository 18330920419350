import React, { useState, useEffect, useRef } from 'react';
import axios from 'src/api/axios';

const CountrySelect = ({ value, onChange, name, className = 'bg-white font-medium border border-gray-300 text-[#000] text-base leading-5 rounded-[4px] focus:ring-primary-500 focus:border-primary-500 block w-full p-[15px]' }) => {
  const [countries, setCountries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get('api/resources/countries');
        setCountries(response.data.data);
        
        // Set initial selected country if value prop exists
        if (value) {
          const country = response.data.data.find(c => c.cca2 === value);
          if (country) {
            setSelectedCountry(country);
            setSearchTerm(country.name);
          }
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const filteredCountries = countries.filter(country =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (Array.isArray(country.altSpellings) && country.altSpellings.some(alt => alt.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const handleSelect = (country) => {
    setSelectedCountry(country);
    setSearchTerm(country.name);
    onChange({ target: { name: name, value: country.cca2 }});
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <input
        type="text"
        name={name}
        placeholder="Search country..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          setIsOpen(true);
        }}
        className={className}
        onClick={() => setIsOpen(true)}
      />
      
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="max-h-60 overflow-y-auto">
            {filteredCountries.map(country => (
              <div
                key={country.id}
                onClick={() => handleSelect(country)}
                className="flex items-center gap-2 p-2 hover:bg-gray-100 cursor-pointer"
              >
                <img src={country.flags.svg} alt={country.flags.alt} className="w-6 h-4 object-cover" />
                <span>{country.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountrySelect;